export default [
  {
    path: '/campaigns',
    name: 'campaign-list',
    component: () => import('@/views/campaigns/campaign-list/CampaignList.vue'),
    meta: {
      layout: 'content',
      resource: 'Campaign',
      action: 'view',
    },
  },

  {
    path: '/campaigns/create',
    name: 'campaign-create',
    component: () => import('@/views/campaigns/campaign-view/CampaignCreate.vue'),
    meta: {
      layout: 'content',
      resource: 'Campaign',
      action: 'create',
    },
  },

  {
    path: '/campaigns/:id',
    name: 'campaign-view',
    component: () => import('@/views/campaigns/campaign-view/CampaignView.vue'),
    meta: {
      layout: 'content',
      resource: 'Campaign',
      action: 'view',
    },
  },

  {
    path: '/campaigns/:id/edit',
    name: 'campaign-edit',
    component: () => import('@/views/campaigns/campaign-view/CampaignEdit.vue'),
    meta: {
      layout: 'content',
      resource: 'Campaign',
      action: 'update',
    },
  },
]
