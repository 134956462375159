// import dashboard from './dashboard'
import business from './business'
import sales from './sales'
import content from './content'
import settings from './settings'

// Array of sections
export default [
  // ...dashboard,
  ...business,
  ...sales,
  ...content,
  ...settings,
]
