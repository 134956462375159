export default [
  {
    path: '/tips',
    name: 'tip-list',
    component: () => import('@/views/tips/tip-list/TipList.vue'),
    meta: {
      layout: 'content',
      resource: 'Tip',
      action: 'view',
    },
  },

  {
    path: '/tips/create',
    name: 'tip-create',
    component: () => import('@/views/tips/tip-view/TipCreate.vue'),
    meta: {
      layout: 'content',
      resource: 'Tip',
      action: 'create',
    },
  },

  {
    path: '/tips/:id',
    name: 'tip-view',
    component: () => import('@/views/tips/tip-view/TipView.vue'),
    meta: {
      layout: 'content',
      resource: 'Tip',
      action: 'view',
    },
  },

  {
    path: '/tips/:id/edit',
    name: 'tip-edit',
    component: () => import('@/views/tips/tip-view/TipEdit.vue'),
    meta: {
      layout: 'content',
      resource: 'Tip',
      action: 'update',
    },
  },
]
