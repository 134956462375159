export default [
  {
    path: '/events',
    name: 'event-list',
    component: () => import('@/views/events/event-list/EventList.vue'),
    meta: {
      layout: 'content',
      resource: 'Event',
      action: 'view',
    },
  },

  {
    path: '/events/create',
    name: 'event-create',
    component: () => import('@/views/events/event-view/EventCreate.vue'),
    meta: {
      layout: 'content',
      resource: 'Event',
      action: 'create',
    },
  },

  {
    path: '/events/:id',
    name: 'event-view',
    component: () => import('@/views/events/event-view/EventView.vue'),
    meta: {
      layout: 'content',
      resource: 'Event',
      action: 'view',
    },
  },

  {
    path: '/events/:id/edit',
    name: 'event-edit',
    component: () => import('@/views/events/event-view/EventEdit.vue'),
    meta: {
      layout: 'content',
      resource: 'Event',
      action: 'update',
    },
  },
]
