const getUrl = userId => suffix => {
  if (!userId) return `/v1/${suffix}`

  return `/v1/users/${userId}/${suffix}`
}

export default (userId = null) => ({
  namespaced: true,
  state: () => ({
    loading: false,
    items: [],
  }),
  getters: {
    loading: state => state.loading,
    items: state => state.items,
  },
  mutations: {
    SET_LOADING(state, loading) {
      state.loading = loading
    },
    SET_ACCESS_TOKENS(state, payload) {
      state.items = payload
    },
  },
  actions: {
    getAccessTokens({ commit }) {
      commit('SET_LOADING', true)

      return new Promise((resolve, reject) => {
        const { _vm: vm } = this

        vm.$http
          .get(getUrl(userId)('tokens'))
          .then(res => {
            commit('SET_ACCESS_TOKENS', res.data?.data || [])
            commit('SET_LOADING', false)
            resolve(true)
          })
          .catch(e => {
            commit('SET_LOADING', false)
            reject(e)
          })
      })
    },
    removeTokens({ commit }, payload) {
      commit('SET_LOADING', true)

      return new Promise((resolve, reject) => {
        const { _vm: vm } = this
        vm.$http
          .delete(getUrl(userId)(`tokens/${payload}`), {
            ids: payload,
          })
          .then(res => {
            commit('SET_ACCESS_TOKENS', res.data?.data || [])
            commit('SET_LOADING', false)
            resolve(true)
          })
          .catch(e => {
            commit('SET_LOADING', false)
            reject(e)
          })
      })
    },
  },
})
