// eslint-disable-next-line no-unused-vars
import { ref, getCurrentInstance, defineComponent } from '@vue/composition-api'

import axios from '@/plugins/axios'

const createFilePondServer = options => ({
  process(fieldName, file, metadata, load, error, progress, abort) {
    // const abortEvent = createEvent()
    const abortEvent = () => {}

    options
      .process(fieldName, file, metadata, progress, abortEvent)
      .then(load)
      .catch(error)

    return {
      abort: () => {
        // abortEvent.emit()
        abort()
      },
    }
  },

  revert: (uniqueFileId, load, error) =>
    options
      .revert(uniqueFileId)
      .then(load)
      .catch(error),

  load: (source, load, error, progress, abort, headers) => {
    const abortController = new AbortController()

    options
      .load(source, progress, abortController, headers)
      .then(load)
      .catch(error)

    return {
      abort: () => {
        abortController.abort()
        abort()
      },
    }
  },

  files: options.files,
})

const uploadFile = async (url, data, progressCallback, abortEvent) =>
  new Promise((resolve, reject) => {
    const abortController = new AbortController()

    abortEvent.bind(() => {
      abortController.abort()
    })

    axios
      .post(url, data, {
        signal: abortController.signal,
        onUploadProgress: evt => progressCallback(evt.lengthComputable, evt.loaded, evt.total),
      })
      .then(
        res => {
          if (res.status < 200 || res.status >= 300) {
            reject(res.statusText)

            return
          }

          resolve(res.data.data || res)
        },
        err => reject(err.statusText || err),
      )
      .catch(err => reject(err.statusText || err))
  })

const FilePondServer = defineComponent(({ disk }) => {
  const files = ref({})

  return createFilePondServer({
    process: async (fieldName, file, metadata, progressCallback, abortEvent) => {
      const response = await axios.post('/v1/filepond/presign')

      const { data: { id = null, uploadUrl = '' } = {} } = response.data

      const formData = new FormData()
      formData.append(fieldName, file, file.name)

      // eslint-disable-next-line no-unused-vars
      const uploader = await uploadFile(
        uploadUrl,
        formData,
        (loaded, total) => progressCallback(total !== undefined, loaded, total),
        abortEvent,
      )

      files.value = { ...files.value, [id]: { ...response.data.data, ...uploader } }

      return id
    },

    load: async (source, progress, abort, headers) => {
      const res = await axios.get(`/v1/filepond/load/${disk}/${source}`, {
        responseType: 'blob',
        signal: abort.signal,
        onDownloadProgress: evt => progress(evt.lengthComputable, evt.loaded, evt.total),
      })

      headers(res.headers.toString())

      return res.data
    },

    revert: async id => axios.delete(files.value[id].deleteUrl),

    files,
  })
})

export default FilePondServer
