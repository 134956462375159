export default [
  {
    path: '/news',
    name: 'news-list',
    component: () => import('@/views/news/news-list/NewsList.vue'),
    meta: {
      layout: 'content',
      resource: 'News',
      action: 'view',
    },
  },

  {
    path: '/news/create',
    name: 'news-create',
    component: () => import('@/views/news/news-view/NewsCreate.vue'),
    meta: {
      layout: 'content',
      resource: 'News',
      action: 'create',
      navActiveLink: 'news-list',
    },
  },

  {
    path: '/news/:id',
    name: 'news-view',
    component: () => import('@/views/news/news-view/NewsView.vue'),
    meta: {
      layout: 'content',
      resource: 'News',
      action: 'view',
      navActiveLink: 'news-list',
    },
  },

  {
    path: '/news/:id/edit',
    name: 'news-edit',
    component: () => import('@/views/news/news-view/NewsEdit.vue'),
    meta: {
      layout: 'content',
      resource: 'News',
      action: 'update',
      navActiveLink: 'news-list',
    },
  },
]
