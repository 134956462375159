export default [
  {
    path: '/item-categories',
    name: 'item-category-list',
    component: () => import('@/views/itemCategories/item-category-list/ItemCategoryList.vue'),
    meta: {
      layout: 'content',
      resource: 'ItemCategory',
      action: 'view',
    },
  },
  {
    path: '/item-categories/:id',
    name: 'item-category-view',
    component: () => import('@/views/itemCategories/item-category-view/ItemCategoryView.vue'),
    meta: {
      layout: 'content',
      resource: 'ItemCategory',
      action: 'view',
    },
  },
]
