export default [
  {
    path: '/news-categories',
    name: 'news-category-list',
    component: () => import('@/views/newsCategories/news-category-list/NewsCategoryList.vue'),
    meta: {
      layout: 'content',
      resource: 'NewsCategory',
      action: 'view',
    },
  },
]
