import { filterTags } from '@/@core/utils/filter'
import { useCoreUtils } from '@core/utils/utils'
import { getCurrentInstance } from '@vue/composition-api'

export default {
  install: app => {
    // eslint-disable-next-line object-curly-newline
    const { toLocalDate, toLocalDateTime, toString, textEllipsis } = useCoreUtils()

    app.filter('toString', str => toString(str))
    app.filter('textEllipsis', (str, length) => textEllipsis(str, length))

    app.filter('toLocalDate', dt => toLocalDate(dt, getCurrentInstance().proxy.$store.getters['auth/locale']))
    app.filter('toLocalDateTime', dt => toLocalDateTime(dt, getCurrentInstance().proxy.$store.getters['auth/locale']))

    app.filter('parseMinutes', val => {
      try {
        const [min, sec] = Number(Number.parseInt(val, 10) / 60)
          .toFixed(2)
          .split('.')

        return `${min.padStart(2, '0')}:${sec} min`
      } catch (e) {
        return null
      }
    })

    app.filter('stripTags', html => filterTags(html))
  },
}
