export default [
  {
    path: '/discoveries',
    name: 'discovery-list',
    component: () => import('@/views/discoveries/discovery-list/DiscoveryList.vue'),
    meta: {
      layout: 'content',
      resource: 'Discovery',
      action: 'view',
    },
  },

  {
    path: '/discoveries/create',
    name: 'discovery-create',
    component: () => import('@/views/discoveries/discovery-view/DiscoveryCreate.vue'),
    meta: {
      layout: 'content',
      resource: 'Discovery',
      action: 'create',
    },
  },

  {
    path: '/discoveries/:id',
    name: 'discovery-view',
    component: () => import('@/views/discoveries/discovery-view/DiscoveryView.vue'),
    meta: {
      layout: 'content',
      resource: 'Discovery',
      action: 'view',
    },
  },

  {
    path: '/discoveries/:id/edit',
    name: 'discovery-edit',
    component: () => import('@/views/discoveries/discovery-view/DiscoveryEdit.vue'),
    meta: {
      layout: 'content',
      resource: 'Discovery',
      action: 'update',
    },
  },
]
