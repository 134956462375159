export default [
  {
    path: '/items',
    name: 'item-list',
    component: () => import('@/views/items/item-list/ItemList.vue'),
    meta: {
      layout: 'content',
      resource: 'Item',
      action: 'view',
    },
  },
  {
    path: '/items/:id',
    name: 'item-view',
    component: () => import('@/views/items/item-view/ItemView.vue'),
    meta: {
      layout: 'content',
      resource: 'Item',
      action: 'view',
    },
  },
]
