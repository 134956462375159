import accessTokens from '../accessTokens'

export default {
  namespaced: true,
  modules: {
    accessTokens: accessTokens(),
  },
  state: () => ({
    user: null,
    token: null,
    loading: false,
    updating: false,

    permissions: [],
  }),
  getters: {
    token: state => state.token,
    user: state => state.user,
    loading: state => state.loading,
    updating: state => state.updating,
    currentUserId: state => state?.user?.id,

    locale: state => state.user?.locale || 'en',

    permissions: state => state.permissions,

    // accessTokens: (state, getters, rootState, rootGetters) => {
    //   console.log(state, getters, rootState, rootGetters)

    //   return state.accessToken
    // },
  },
  mutations: {
    SET_LOADING(state, loading) {
      state.loading = loading
    },
    SET_UPDATING(state, updating) {
      state.updating = updating
    },
    SET_TOKEN(state, payload) {
      state.token = payload

      localStorage.setItem('accessToken', payload)

      // this.vm.$http.setFixture({
      //   locale: payload.user.locale,
      //   timezone: payload.user.timezone,
      //   militaryTime: payload.user.militaryTime
      // })
    },
    LOGOUT(state) {
      state.user = null
      state.token = null
      state.loading = false
      state.updating = false

      state.permissions = []

      delete localStorage.vuex

      delete localStorage.user
      delete localStorage.userData
      delete localStorage.userAbility
      delete localStorage.accessToken
    },
    SET_USER(state, payload) {
      state.user = payload

      // this.vm.$http.setFixture({
      //   locale: payload.locale,
      //   timezone: payload.timezone,
      //   militaryTime: payload.militaryTime
      // })
    },
    SET_AUTH(state, payload) {
      const { _vm: vm } = this

      state.user = payload.user
      state.token = payload.token

      // state.permissions = payload.permissions

      state.permissions = payload.permissions.map(p => {
        const [subject, action] = p !== '*' ? p.split(':') : ['all', 'manage']

        return {
          subject,
          action,
        }
      })

      localStorage.setItem('userData', JSON.stringify(payload.user))
      localStorage.setItem('userAbility', JSON.stringify(state.permissions))
      localStorage.setItem('accessToken', `${payload.token.type} ${payload.token.value}`)

      vm.$ability.update(state.permissions)

      // this.vm.$http.setFixture({
      //   locale: payload.locale,
      //   timezone: payload.timezone,
      //   militaryTime: payload.militaryTime
      // })
    },

    SET_LOCALE(state, payload) {
      state.user = {
        ...state.user,
        locale: payload,
      }
    },
  },
  actions: {
    email({ commit }, formData) {
      commit('SET_LOADING', true)

      return new Promise((resolve, reject) => {
        const { _vm: vm } = this

        vm.$http
          .post('v1/auth/email', formData)
          .then(() => {
            commit('SET_LOADING', false)
            resolve(true)
          })
          .catch(e => {
            commit('SET_LOADING', false)
            reject(e)
          })
      })
    },
    login({ commit }, formData) {
      commit('SET_LOADING', true)

      return new Promise((resolve, reject) => {
        const { _vm: vm } = this

        vm.$http
          .post('v1/auth/login', formData)
          .then(res => {
            const { data } = res.data

            commit('SET_AUTH', {
              user: data.user,
              token: data.token,
              permissions: data.permissions,
            })
            commit('SET_LOADING', false)
            resolve(true)
          })
          .catch(e => {
            commit('SET_LOADING', false)
            reject(e)
          })
      })
    },

    async logout({ commit, getters }) {
      if (getters.token) {
        const { _vm: vm } = this
        vm.$http.post('/v1/logout')
      }

      commit('LOGOUT')
      commit('AFTER_LOGOUT', null, { root: true })
    },

    getProfile({ commit }) {
      return new Promise((resolve, reject) => {
        const { _vm: vm } = this

        vm.$http
          .get('/v1/profile')
          .then(res => {
            commit('SET_USER', res.data?.data?.user)
            resolve(true)
          })
          .catch(e => {
            reject(e)
          })
      })
    },

    getAccessTokens({ dispatch }) {
      dispatch('auth/accessTokens/getAccessTokens', null, { root: true })
    },

    update({ commit }, formData) {
      commit('SET_UPDATING', true)

      return new Promise((resolve, reject) => {
        const { _vm: vm } = this

        vm.$http
          .post('v1/profile', formData)
          .then(res => {
            commit('SET_USER', res.data?.data?.user)
            commit('SET_UPDATING', false)
            resolve(true)
          })
          .catch(e => {
            console.error(e)
            commit('SET_UPDATING', false)
            reject(e)
          })
      })
    },

    // changePassword({ commit }, formData) {
    //   commit('SET_UPDATING', true)

    //   return new Promise((resolve, reject) => {
    //     const { _vm: vm } = this

    //     vm.$http
    //       .post('v1/change-password', formData)
    //       .then(res => {
    //         console.log(res)
    //         commit('SET_UPDATING', false)
    //         resolve(true)
    //       })
    //       .catch(e => {
    //         console.dir(e)
    //         commit('SET_UPDATING', false)
    //         reject(e)
    //       })
    //   })
    // },

    setLocale({ commit }, payload) {
      const { _vm: vm } = this
      const formData = new FormData()
      formData.append('locale', payload)

      vm.$http.post('/v1/set-locale', formData).then(() => {
        commit('SET_LOCALE', payload)
      })
    },
  },
}
