export default {
  install: (Vue, { store }) => {
    if (!store) throw new Error('Please provide Vuex store.')

    // eslint-disable-next-line no-param-reassign
    Vue.prototype.$snackbar = {
      show(data) {
        store.dispatch('snackbar/showMessage', data, { root: true })
      },
      info(data) {
        store.dispatch('snackbar/info', data, { root: true })
      },
      success(data) {
        store.dispatch('snackbar/success', data, { root: true })
      },
      warning(data) {
        store.dispatch('snackbar/warning', data, { root: true })
      },
      error(data) {
        store.dispatch('snackbar/error', data, { root: true })
      },
      remove(id) {
        store.dispatch('snackbar/removeMessage', id, { root: true })
      },
    }
  },
}
