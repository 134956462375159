<template>
  <div
    :class="$vuetify.theme.isDark ? 'theme--dark' : 'theme--light'"
    class="v-input v-input--is-label-active v-text-field v-text-field--is-booted d-block v-input--is-focused"
  >
    <div class="position-relative">
      <label
        for="pin"
        class="v-label v-label--active"
        :class="[
          pinFocused ? 'primary--text' : '',
          errorMessages.length || pinHasError ? 'error--text' : '',
          $vuetify.theme.isDark ? 'theme--dark' : 'theme--light'
        ]"
        style="left: 0px; right: auto; position: absolute;"
      >{{ t(label) }}</label>
      <v-otp-input
        ref="pinRef"
        v-bind="$attrs"
        plain
        :name="name"
        :autofocus="true"
        :length="length"
        type="password"
        :label="t(label)"
        hide-details="auto"
        :rules="rules"
        :error-messages="errorMessages"
        v-on="{
          ...$listeners,
        }"
        @finish="onFinish"
      ></v-otp-input>

      <div
        v-if="errorMessages.length"
        class="v-text-field__details"
      >
        <div
          class="v-messages theme--dark error--text"
          role="alert"
        >
          <div class="v-messages__wrapper">
            <div
              v-for="error in errorMessages"
              :key="error"
              class="v-messages__message"
            >
              {{ error }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed } from '@vue/composition-api'
import { useUtils } from '@/@core/libs/i18n'
import { required } from '@core/utils/validation'

export default {
  name: 'VOtpField',

  model: {
    prop: 'pin',
    event: 'input',
  },

  props: {
    pin: {
      type: String,
      default: () => '',
    },

    name: {
      type: String,
      default: () => 'pin',
    },

    label: {
      type: String,
      default: () => 'PIN',
    },

    length: {
      type: String,
      default: () => '4',
    },

    errorMessages: {
      type: Array,
      default: () => [],
    },

    handleCallback: {
      type: Function,
      default: () => {},
    },

    rules: {
      type: Array,
      default: () => [],
    },
  },
  setup(props) {
    const { t } = useUtils()

    const pinRef = ref(null)

    const onFinish = val => {
      if (Number(pinRef.value.length) === Number(val.length)) {
        props.handleCallback()
      }
    }

    return {
      t,

      pinRef,
      pinFocused: computed(() => pinRef.value?.isFocused),
      pinHasError: computed(() => pinRef.value?.messagesToDisplay?.length),

      onFinish,

      validators: {
        required,
      },
    }
  },
}
</script>
