import { render, staticRenderFns } from "./ImportCard.vue?vue&type=template&id=5512923c&scoped=true&"
import script from "./ImportCard.vue?vue&type=script&lang=js&"
export * from "./ImportCard.vue?vue&type=script&lang=js&"
import style0 from "./ImportCard.vue?vue&type=style&index=0&id=5512923c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5512923c",
  null
  
)

export default component.exports