import { canNavigate } from '@/plugins/acl/routeProtection'
import Vue from 'vue'
import VueRouter from 'vue-router'
import discoveries from './discoveries'
import auth from './auth'
import customers from './customers'
import dashboard from './dashboard'
import itemCategories from './itemCategories'
import items from './items'
import misc from './misc'
import saleInvoices from './saleInvoices'
import saleOrders from './saleOrders'
import users from './users'
import events from './events'
import news from './news'
import newsCategories from './newsCategories'
import tips from './tips'
import academies from './academies'
import campaigns from './campaigns'
import settings from './settings'

Vue.use(VueRouter)

const routes = [
  // ? We are redirecting to different pages based on role.
  // NOTE: Role is just for UI purposes. ACL is based on abilities.
  {
    path: '/',
    redirect: to => {
      const userData = JSON.parse(localStorage.getItem('userData'))

      // const userRole = userData && userData.role ? userData.role : null

      if (userData) {
        return {
          name: 'sale-order-list',

          // name: 'dashboard-crm'
        }
      }

      // if (userRole === 'admin') return { name: 'dashboard-crm' }
      // if (userRole === 'user') return { name: 'page-access-control' }

      return { name: 'auth-login', query: to.query }
    },
  },

  ...auth,

  ...dashboard,
  ...misc,
  ...users,
  ...customers,
  ...itemCategories,
  ...items,
  ...saleOrders,
  ...saleInvoices,

  ...events,
  ...news,
  ...newsCategories,
  ...tips,
  ...academies,
  ...campaigns,
  ...discoveries,

  ...settings,

  {
    // name: 'error-404',
    path: '*',
    component: () => import('@/views/miscellaneous/Error404.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
    },
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, _from, pos) {
    const DEFAULT_POSITION = { x: 0, y: 0 }

    if (to.hash) return { selector: to.hash, behavior: 'smooth' }

    // return { x: 0, y: 0 }

    return new Promise(resolve => {
      setTimeout(() => {
        resolve({
          ...(pos || DEFAULT_POSITION),
          behavior: 'smooth',
        })
      }, 500)
    })
  },
})

// ? Router Before Each hook: https://router.vuejs.org/guide/advanced/navigation-guards.html
router.beforeEach((to, _, next) => {
  const userData = localStorage.getItem('userData')
  const isLoggedIn = userData && localStorage.getItem('accessToken') && localStorage.getItem('userAbility')

  if (!canNavigate(to)) {
    // Redirect to login if not logged in
    if (!isLoggedIn) return next({ name: 'auth-login', query: { marketplace: to.query.marketplace } })

    // If logged in => not authorized
    return next({ name: 'misc-not-authorized' })

    // return next({ name: 'misc-not-authorized' })
  }

  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    next('/')
  }

  return next()
})

export default router
