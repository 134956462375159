<template>
  <v-card
    v-bind="$attrs"
    elevation="4"
    class="mx-auto script-card"
  >
    <v-card-text class="">
      <div class="text-uppercase">
        <small>{{ t('SCRIPT') }}</small>
      </div>

      <div class="d-flex flex-row justify-space-between">
        <div
          class="text-h5"
          :class="colorScheme(script.loading)()"
        >
          {{ t(title || script.entity) }}
        </div>

        <v-tooltip
          v-if="script.loading"
          bottom
        >
          <template #activator="{ on, attrs }">
            <v-icon
              v-bind="attrs"
              :color="script.loading ? 'red' : 'transparent'"
              v-on="on"
            >
              {{ icons.mdiDatabaseAlertOutline }}
            </v-icon>
          </template>
          <span v-if="script.loading">{{ t('Script is running!') }}</span>
          <span v-else>{{ t('Script is not running!') }}</span>
        </v-tooltip>
      </div>
    </v-card-text>

    <v-expand-transition>
      <v-card
        v-if="expanded"
        class="transition-fast-in-fast-out v-card--reveal script-card"
        style="height: 100%;"
      >
        <v-card-text>
          <div class="text-uppercase">
            <small>{{ t('SCRIPT') }}</small>
          </div>

          <div class="text-h5 primary--text d-flex flex-row justify-space-between">
            {{ t(title || script.entity) }}
          </div>

          <div class="secondary--text">
            <small>{{ t(script.description) }}</small>
          </div>
        </v-card-text>

        <v-card-actions class="pt-0">
          <v-btn
            text
            small
            color="light-blue darken-2"
            @click="$emit('toggle', null)"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-expand-transition>

    <v-card-actions class="d-flex flex-row justify-space-between">
      <v-btn
        text
        small
        color="light-blue darken-2"
        @click="$emit('toggle', script.entity)"
      >
        Learn More
      </v-btn>

      <v-btn
        text
        small
        :loading="script.loading"
        :disabled="executing && !script.loading"
        :color="colorScheme(script.loading)(false)"
        @click="() => $emit('execute', script.entity)"
      >
        <span v-if="script.loading">{{ t('Stop Script') }}</span>
        <span v-else>{{ t('Run Script') }}</span>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { useUtils } from '@core/libs/i18n'
import { mdiDatabaseAlertOutline } from '@mdi/js'

export default {
  name: 'ImportCard',

  props: {
    title: {
      type: String,
      required: false,
      default: () => '',
    },
    script: {
      type: Object,
      required: true,
    },
    expanded: {
      type: Boolean,
      required: true,
      default: () => false,
    },
    executing: {
      type: Boolean,
      required: true,
      default: () => false,
    },
  },

  emits: ['execute', 'toggle'],

  setup() {
    const { t } = useUtils()

    // eslint-disable-next-line operator-linebreak
    const colorScheme =
      load =>
      // eslint-disable-next-line indent
      (text = true) =>
        // eslint-disable-next-line indent
        `${load ? 'red' : 'primary'}${text ? '--text' : ''}`

    return {
      t,

      colorScheme,

      icons: {
        mdiDatabaseAlertOutline,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.v-card--reveal {
  bottom: 0;
  z-index: 1;
  width: 100%;
  position: absolute;
  opacity: 1 !important;
}

.script-card {
  height: 180px;
  display: flex;
  flex: 1 0 100%;
  flex-direction: column;
  justify-content: space-between;
}
</style>
