import { DateTime } from 'luxon'

export const DEFAULT_DATETIME_FORMAT = 'yyyy-MM-dd HH:mm'

export const toLocalDateTime = (dt, locale) =>
  DateTime.fromISO(dt, { zone: 'UTC' }).toLocaleString(DateTime.DATETIME_SHORT, { locale })

export const toLocalDate = (dt, locale) =>
  DateTime.fromISO(dt, { zone: 'UTC' }).toLocaleString(DateTime.DATE_SHORT, { locale })

export const toDateTimeFormat = (dt, fmt = DEFAULT_DATETIME_FORMAT) =>
  DateTime.fromISO(dt, { zone: 'UTC' }).toFormat(fmt)

export const today = () => DateTime.fromJSDate(new Date())

export const isValidDate = (date = '', fmt = 'yyyy-LL-dd') => date && DateTime.fromFormat(date, fmt).isValid

export const isValidTime = (time = '', fmt = 'HH:mm') => time && DateTime.fromFormat(time, fmt).isValid

export const isValidDateTime = (dateTime = '', fmt = 'yyyy-LL-dd HH:mm') =>
  dateTime && DateTime.fromFormat(dateTime, fmt).isValid
