// eslint-disable-next-line object-curly-newline
import { mdiAccountCashOutline, mdiAccountOutline, mdiDetails, mdiFormatListBulleted } from '@mdi/js'

export default [
  {
    title: 'Administrators',
    to: 'user-list',
    icon: mdiAccountOutline,

    // children: [
    //   {
    //     title: 'User List',
    //     to: 'user-list',
    //   },
    //   {
    //     title: 'User View',
    //     to: { name: 'user-view', params: { id: 21 } },
    //   },
    // ],
  },
  {
    title: 'Customers',
    to: 'customer-list',
    icon: mdiAccountCashOutline,
  },
  {
    title: 'Products',
    to: 'item-list',
    icon: mdiFormatListBulleted,
  },
  {
    title: 'Categories',
    to: 'item-category-list',
    icon: mdiDetails,
  },
]
