export default [
  {
    path: '/academies',
    name: 'academy-list',
    component: () => import('@/views/academies/academy-list/AcademyList.vue'),
    meta: {
      layout: 'content',
      resource: 'Academy',
      action: 'view',
    },
  },

  {
    path: '/academies/create',
    name: 'academy-create',
    component: () => import('@/views/academies/academy-view/AcademyCreate.vue'),
    meta: {
      layout: 'content',
      resource: 'Academy',
      action: 'create',
    },
  },

  {
    path: '/academies/:id',
    name: 'academy-view',
    component: () => import('@/views/academies/academy-view/AcademyView.vue'),
    meta: {
      layout: 'content',
      resource: 'Academy',
      action: 'view',
    },
  },

  {
    path: '/academies/:id/edit',
    name: 'academy-edit',
    component: () => import('@/views/academies/academy-view/AcademyEdit.vue'),
    meta: {
      layout: 'content',
      resource: 'Academy',
      action: 'update',
    },
  },
]
