// eslint-disable-next-line import/prefer-default-export
export const toString = str => String(str).toString()

export const textEllipsis = (string = '', length = 75, padWith = '...') => {
  const str = String(string)

  if (!str || !str.length) {
    return ''
  }

  if (str.length <= length) {
    return str
  }

  return `${str.substring(0, length)}${padWith}`
}

export const slugify = (string = '', separator = '-') => {
  const flip = separator === '-' ? '_' : '-'

  return string
    .toLowerCase()
    .replaceAll(new RegExp(`/[${flip}]/`, 'gmiu'), separator)
    .replaceAll(/@/gmi, `${separator}at${separator}`)
    .replaceAll(new RegExp(`[^\\${separator}\\p{L}\\p{N}\\s]+`, 'gmiu'), '')
    .replaceAll(new RegExp(`[\\${separator}\\s]+`, 'gmiu'), separator)
}
