// eslint-disable-next-line import/no-extraneous-dependencies
import { Heading as TiptapHeading } from 'element-tiptap'
import { ParagraphNodeSpec } from '@core/utils/tiptap/Paragraph'

export default class Heading extends TiptapHeading {
  get schema() {
    const {
      defining, draggable, parseDOM, toDOM,
    } = super.schema

    return {
      ...ParagraphNodeSpec,
      attrs: {
        ...ParagraphNodeSpec.attrs,
        level: {
          default: 1,
        },
      },
      defining,
      draggable,
      parseDOM,
      toDOM,
    }
  }
}
