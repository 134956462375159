import { VCard } from 'vuetify/lib/components/VCard';
import { VInput } from 'vuetify/lib/components/VInput';
import { VSheet } from 'vuetify/lib/components/VSheet';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VInput,{staticClass:"el-tiptap--wrapper v-input--is-focused",style:({
    transformOrigin: 'top left'
  }),attrs:{"rules":_vm.rules,"active":true,"label":_vm.label,"value":_vm.value,"error-messages":_vm.errorMessages},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('div',{staticClass:"v-text-field__slot"},[_c(VSheet,{staticClass:"w-100",attrs:{"rounded":"","elevation":"4"}},[_c(VCard,{attrs:{"elevation":"4"}},[_c('el-tiptap',_vm._g(_vm._b({ref:"editorRef",attrs:{"extensions":_vm.extensions,"content":_vm.content},on:{"onInit":_vm.onInit,"onUpdate":_vm.onUpdate}},'el-tiptap',_vm.$attrs,false),Object.assign({}, _vm.$on,
              _vm.$listeners)))],1)],1)],1)]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }