<template>
  <file-pond
    :ref="ref"
    v-bind="$attrs"
    :credits="false"
    :force-revert="true"
    :label-idle="labelIdle"
    :server="filePondServer"
    @init="handleFilePondInit"
    v-on="$listeners"
  />
</template>

<script>
import vueFilePond from 'vue-filepond'
import { ref } from '@vue/composition-api'
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'
import FilePondPluginImagePreview from 'filepond-plugin-image-preview'

// import filePondServer from '@core/utils/filePondServer'
import createFilePondServer from '@core/utils/filePond'

import 'filepond/dist/filepond.min.css'
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css'

const FilePond = vueFilePond(FilePondPluginFileValidateType, FilePondPluginImagePreview)

export default {
  name: 'FilePondInput',

  components: {
    FilePond,
  },

  props: {
    ref: {
      type: String,
      default: () => 'pond',
    },

    disk: {
      type: String,
      required: true,
    },

    labelIdle: {
      type: String,
      default: () => 'Drag & Drop your file or <span class="filepond--label-action"> Browse </span>',
    },
  },

  setup(props) {
    const files = ref([])

    const handleFilePondInit = () => {}

    // console.log(createFilePondServer())

    return {
      files,

      handleFilePondInit,

      filePondServer: createFilePondServer({
        disk: props.disk,
      }),
    }
  },
}
</script>

<style lang="scss">
.v-application {
  &.theme--dark {
    .filepond--panel-root {
      background-color: map-get($material-dark, 'background');
      border: 2px solid map-get($material-dark, 'background');
    }
    .filepond--drop-label {
      color: map-get($material-dark, 'text-color');
    }
  }
  &.theme--light {
    .filepond--panel-root {
      background-color: map-get($material-light, 'background');
      border: 2px solid map-get($material-light, 'background');
    }
    .filepond--drop-label {
      color: map-get($material-light, 'text-color');
    }
  }
}

.filepond--wrapper {
  .filepond--root {
    margin-bottom: 0;
    font-family: $body-font-family;

    .filepond--drop-label label {
      font-size: 0.875em;
    }
  }
}
</style>
