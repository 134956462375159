export default [
  {
    path: '/sale-orders',
    name: 'sale-order-list',
    component: () => import('@/views/saleOrders/sale-order-list/SaleOrderList.vue'),
    meta: {
      layout: 'content',
      resource: 'SaleOrder',
      action: 'view',
    },
  },
  {
    path: '/sale-orders/:id',
    name: 'sale-order-view',
    component: () => import('@/views/saleOrders/sale-order-view/SaleOrderView.vue'),
    meta: {
      layout: 'content',
      resource: 'SaleOrder',
      action: 'view',
    },
  },
]
