// eslint-disable-next-line object-curly-newline
import {
  mdiDetails,
  mdiAccountOutline,
  mdiAccountCashOutline,
  mdiFormatListBulleted,

  // mdiCalendarClockOutline,
} from '@mdi/js'

export default [
  {
    subheader: 'BUSINESS',
    resource: 'Public',
    action: 'read',
  },
  {
    title: 'Administrators',
    to: 'user-list',
    icon: mdiAccountOutline,
    resource: 'User',
    action: 'view',
  },
  {
    title: 'Customers',
    to: 'customer-list',
    icon: mdiAccountCashOutline,
    resource: 'Customer',
    action: 'view',
  },

  // {
  //   title: 'Events',
  //   to: 'event-list',
  //   icon: mdiCalendarClockOutline,
  //   resource: 'Event',
  //   action: 'view',
  // },
  {
    title: 'Products',
    to: 'item-list',
    icon: mdiFormatListBulleted,
    resource: 'Item',
    action: 'view',
  },
  {
    title: 'Categories',
    to: 'item-category-list',
    icon: mdiDetails,
    resource: 'ItemCategory',
    action: 'view',
  },
]
