// eslint-disable-next-line import/no-extraneous-dependencies
import { Paragraph as TiptapParagraph } from 'tiptap'
import { transformLineHeightToCSS, transformCSStoLineHeight } from 'element-tiptap/src/utils/line_height'
import { ALIGN_PATTERN } from 'element-tiptap/src/constants'

function toDOM(node) {
  // console.log('node', node)
  const {
    textAlign,
    indent,
    lineHeight,
    fontFamily,
  } = node.attrs

  let style = ''
  const attrs = {}

  if (textAlign && textAlign !== 'left') {
    style += `text-align: ${textAlign};`
  }

  if (indent) {
    attrs['data-indent'] = indent
  }

  if (lineHeight) {
    const cssLineHeight = transformLineHeightToCSS(lineHeight)
    style += `line-height: ${cssLineHeight};`
  }

  if (fontFamily) {
    style += `font-family: ${fontFamily};`
  }

  // eslint-disable-next-line no-unused-expressions
  style && (attrs.style = style)

  return ['p', attrs, 0]
}

function getAttrs(dom) {
  // console.log('dom', dom, this)
  let {
    // eslint-disable-next-line prefer-const
    textAlign,
    lineHeight,
    fontFamily,
  } = dom.style

  let align = dom.getAttribute('data-text-align') || textAlign || ''
  align = ALIGN_PATTERN.test(align) ? align : null

  const indent = parseInt(dom.getAttribute('data-indent'), 10) || 0

  lineHeight = transformCSStoLineHeight(lineHeight) || null

  fontFamily = dom.getAttribute('data-font-family') || fontFamily || ''

  return {
    textAlign: align,
    indent,
    lineHeight,
    fontFamily,
  }
}

export const ParagraphNodeSpec = {
  attrs: {
    textAlign: { default: null },
    indent: { default: null },
    lineHeight: { default: null },
    fontFamily: { default: null },
  },
  content: 'inline*',
  group: 'block',
  parseDOM: [{
    tag: 'p',
    getAttrs,
  }],
  toDOM,
}

export default class Paragraph extends TiptapParagraph {
  // eslint-disable-next-line class-methods-use-this
  get schema() {
    return {
      ...ParagraphNodeSpec,
      attrs: {
        ...ParagraphNodeSpec.attrs,
        ...this.options,
      },
    }
  }

  // init() {
  //   console.error('init', this)
  //   return null
  // }
}

export const toParagraphDOM = toDOM
export const getParagraphNodeAttrs = getAttrs
