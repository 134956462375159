export default [
  {
    path: '/settings',
    name: 'settings',
    component: () => import('@/views/settings/SettingsView.vue'),
    meta: {
      layout: 'content',
      resource: '*',
      action: 'view',
    },
  },
]
