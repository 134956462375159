<template>
  <div class="v-filepond-container">
    <div
      :class="$vuetify.theme.isDark ? 'theme--dark' : 'theme--light'"
      class="v-input v-input--is-label-active v-text-field v-text-field--is-booted d-block"
    >
      <div class="v-input__control">
        <div class="v-input__slot flex-column">
          <div class="v-text-field__slot w-100">
            <label
              :for="name"
              class="v-label v-label--active"
              :class="{
                'theme--light': !$vuetify.theme.isDark,
                'theme--dark': $vuetify.theme.isDark,
                'error--text': errors.length
              }"
              style="left: 0; right: auto; position: absolute;"
            >{{ (label || name) | capitalize }}</label>

            <file-pond
              :ref="ref"
              :name="name"
              v-bind="$attrs"
              :files="controller.files"
              :credits="false"
              :force-revert="true"
              :label-idle="labelIdle"
              :server="filePondServer"
              class="w-100"
              allow-image-validate-size
              allow-file-size-validation
              allow-file-type-validation
              :allow-reorder="$attrs['allow-multiple'] || false"
              :accepted-file-types="acceptedFileTypes"
              v-on="$listeners"
              @init="cb.init"
              @addfile="cb.addFile"
              @addfilestart="cb.addFileStart"
              @error="cb.handleError"
              @processfile="cb.processFile"
              @removefile="cb.removeFile"
              @reorderfiles="cb.reorderFiles"
            />
          </div>

          <div class="v-text-field__details align-self-start">
            <div
              class="v-messages error--text"
              :class="$vuetify.theme.isDark ? 'theme--dark' : 'theme--light'"
              role="alert"
            >
              <div class="v-messages__wrapper">
                <div
                  v-for="error in errors"
                  :key="error.toString()"
                  class="v-messages__message"
                >
                  {{ error }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import vueFilePond from 'vue-filepond'
import FilePondPluginImagePreview from 'filepond-plugin-image-preview'
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size'
import FilePondPluginImageValidateSize from 'filepond-plugin-image-validate-size'
import createFilePondServer from '@core/utils/filePond'

import 'filepond/dist/filepond.min.css'
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css'
import { capitalize } from 'element-tiptap/src/utils/shared'

const FilePond = vueFilePond(
  FilePondPluginImagePreview,
  FilePondPluginFileValidateType,
  FilePondPluginFileValidateSize,
  FilePondPluginImageValidateSize,
)

export default {
  name: 'VFilePond',

  components: {
    FilePond,
  },

  inheritAttrs: false,

  // model: {
  //   prop: 'controller',
  //   event: 'input',
  // },

  props: {
    controller: {
      type: Object,
      default: () => ({
        files: [],
        uploading: false,
      }),
    },

    ref: {
      type: String,
      default: () => 'pond',
    },

    disk: {
      type: String,
      required: true,
    },

    name: {
      type: String,
      required: true,
    },

    label: {
      type: String,
      required: true,
    },

    labelIdle: {
      type: String,
      default: () => 'Drag & Drop your file or <span class="filepond--label-action"> Browse </span>',
    },

    // files: {
    //   type: Array,
    //   default: () => [],
    // },

    maxFileSize: {
      type: String,
      default: () => null,
    },

    acceptedFileTypes: {
      type: Array,
      default: () => [],
    },

    errors: {
      type: Array,
      default: () => [],
    },
  },
  methods: { capitalize },

  // eslint-disable-next-line no-unused-vars
  setup(props, { emit }) {
    // const files = ref(props.controller.files)

    const init = () => {}

    // eslint-disable-next-line no-unused-vars
    const addFileStart = file => {
      // console.error('addFileStart', file)

      emit('update:controller', {
        ...props.controller,

        // files: Array.isArray(props.controller.files)
        //   ? [...props.controller.files, !props.controller.files?.find(f => f.source === file.source) && file]
        //   : file,

        files: props.controller.files.find(f => f.source === file.source)
          ? props.controller.files
          : [...props.controller.files, file],

        uploading: !props.controller.files.find(f => f.source === file.source),
      })
    }

    // eslint-disable-next-line no-unused-vars
    const addFile = (err, file) => {
      // console.log('addFile', file)

      emit('update:controller', {
        ...props.controller,

        // files: Array.isArray(props.controller.files)
        //   ? [...props.controller.files, !props.controller.files?.find(f => (f === file || f.source === file.filename)) && file]
        //   : file,
      })

      // if (!props.controller.files.find(f => (f === file || f.source === file.filename))) {
      //   console.log('addemit')
      //   emit('update:controller', { uploading: true })
      // }
    }

    // eslint-disable-next-line no-unused-vars
    const processFile = (err, file) => {
      // console.log('processFile', file)

      emit('update:controller', {
        ...props.controller,

        files: props.controller.files.find(f => f.source === file.source)
          ? props.controller.files
          : [...props.controller.files, file],

        // files: Array.isArray(props.controller.files)
        //   ? [...props.controller.files.map(f => (f === file || f.source === file.filename) ? file : f)]
        //   : file,

        uploading: false,
      })
    }

    // eslint-disable-next-line no-unused-vars
    const removeFile = (err, file) => {
      // console.log('removeFile', file)

      emit('update:controller', {
        ...props.controller,

        files: props.controller.files.filter(f => {
          if (f.filename) {
            return f.filename !== file.filename
          }

          if (file.serverId) {
            return f.source !== file.serverId
          }

          return f.source !== file.filename
        }),

        // files: Array.isArray(props.controller.files)
        //   ? []
        //   : null,

        uploading: false,
      })
    }

    // eslint-disable-next-line no-unused-vars
    const handleError = (err, file) => {
      // console.log('handleError', file)

      emit('update:controller', {
        ...props.controller,

        files: file !== null
          ? props.controller.files.filter(f => (f === file || f.source === file.filename))
          : [],

        // files: Array.isArray(props.controller.files)
        //   ? []
        //   : null,

        uploading: false,
      })
    }

    // eslint-disable-next-line no-unused-vars
    const reorderFiles = (files, origin, target) => {
      // console.log(files, origin, target)
      // files.map(file => console.log(file.getMetadata()))
    }

    return {
      cb: {
        init,
        addFile,
        addFileStart,
        handleError,
        processFile,
        removeFile,
        reorderFiles,
      },

      filePondServer: createFilePondServer({
        disk: props.disk,
      }),
    }
  },
}
</script>

<style lang="scss">
.v-application {
  &.theme--dark {
    .filepond--panel-root {
      background-color: map-get($material-dark, 'background');
      border: 2px solid map-get($material-dark, 'background');
    }
    .filepond--drop-label {
      color: map-get($material-dark, 'text-color');
    }
  }
  &.theme--light {
    .filepond--panel-root {
      background-color: map-get($material-light, 'background');
      border: 2px solid map-get($material-light, 'background');
    }
    .filepond--drop-label {
      color: map-get($material-light, 'text-color');
    }
  }
}

.filepond--wrapper {
  .filepond--root {
    margin-bottom: 0;
    font-family: $body-font-family;

    .filepond--drop-label label {
      font-size: 0.875em;
    }
  }
}
</style>

<style lang="scss" scoped>
.v-filepond-container {
  .v-input {
    .v-input__control {
      .v-input__slot {
        cursor: inherit;

        &:before,
        &:after {
          content: unset;
        }

        .v-text-field__slot {
          .filepond--wrapper {
            padding: 8px 0 8px;
          }
        }
      }
    }
  }
}
</style>
