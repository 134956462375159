export default {
  install: Vue => {
    if (Vue.prototype.$http === undefined) {
      throw new Error('$http is undefined.')
    }

    const { $http } = Vue.prototype

    // eslint-disable-next-line no-param-reassign
    Vue.prototype.$http.repository = {
      auth: {
        email: formData => $http.post('/v1/auth/email', formData),
        login: formData => $http.post('/v1/auth/login', formData),
        logout: () => $http.post('/v1/auth/logout'),
      },
      profile: {
        get: () => $http.get('/v1/profile'),
        update: formData => $http.post('/v1/profile', formData),
        setLocale: formData => $http.post('/v1/set-locale', formData),
      },
      users: {
        getAll: params => $http.get('/v1/users', params),
        get: id => $http.get(`/v1/users/${id}`),
        create: formData => $http.post('/v1/users/create', formData),
        update: (id, formData) => $http.post(`/v1/users/${id}`, formData),
        toggleStatus: id => $http.post(`/v1/users/${id}/toggle-status`),
        destroy: id => $http.delete(`/v1/users/${id}`),
        accessTokens: id => $http.get(`/v1/users/${id}/tokens`),
      },
      items: {
        getAll: params => $http.get('/v1/items', params),
        get: id => $http.get(`/v1/items/${id}`),
      },
      itemCategories: {
        getAll: params => $http.get('/v1/item-categories', params),
        get: id => $http.get(`/v1/item-categories/${id}`),
        items: id => $http.get(`/v1/item-categories/${id}/items`),
        categories: id => $http.get(`/v1/item-categories/${id}/categories`),
      },
      academies: {
        getAll: params => $http.get('/v1/academies', params),
        get: id => $http.get(`/v1/academies/${id}`),
        create: formData => $http.post('/v1/academies/create', formData),
        update: (id, formData) => $http.post(`/v1/academies/${id}`, formData),
        destroy: id => $http.delete(`/v1/academies/${id}`),
        order: (id, formData) => $http.post(`/v1/academies/${id}/reorder`, formData),
      },
      campaigns: {
        getAll: params => $http.get('/v1/campaigns', params),
        get: id => $http.get(`/v1/campaigns/${id}`),
        create: formData => $http.post('/v1/campaigns/create', formData),
        update: (id, formData) => $http.post(`/v1/campaigns/${id}`, formData),
        destroy: id => $http.delete(`/v1/campaigns/${id}`),
      },
      customers: {
        getAll: params => $http.get('/v1/customers', params),
        get: id => $http.get(`/v1/customers/${id}`),
        saleOrders: id => $http.get(`/v1/customers/${id}/sale-orders`),
        saleInvoices: id => $http.get(`/v1/customers/${id}/sale-invoices`),
      },
      discoveries: {
        getAll: params => $http.get('/v1/discoveries', params),
        get: id => $http.get(`/v1/discoveries/${id}`),
        create: formData => $http.post('/v1/discoveries/create', formData),
        update: (id, formData) => $http.post(`/v1/discoveries/${id}`, formData),
        destroy: id => $http.delete(`/v1/discoveries/${id}`),
      },
      events: {
        getAll: params => $http.get('/v1/events', params),
        get: id => $http.get(`/v1/events/${id}`),
        create: formData => $http.post('/v1/events/create', formData),
        update: (id, formData) => $http.post(`/v1/events/${id}`, formData),
        destroy: id => $http.delete(`/v1/events/${id}`),
      },
      news: {
        getAll: params => $http.get('/v1/news', params),
        get: id => $http.get(`/v1/news/${id}`),
        create: formData => $http.post('/v1/news/create', formData),
        update: (id, formData) => $http.post(`/v1/news/${id}`, formData),
        destroy: id => $http.delete(`/v1/news/${id}`),
      },
      tips: {
        getAll: params => $http.get('/v1/tips', params),
        get: id => $http.get(`/v1/tips/${id}`),
        create: formData => $http.post('/v1/tips/create', formData),
        update: (id, formData) => $http.post(`/v1/tips/${id}`, formData),
        destroy: id => $http.delete(`/v1/tips/${id}`),
        order: (id, formData) => $http.post(`/v1/tips/${id}/reorder`, formData),
      },
      saleOrders: {
        getAll: params => $http.get('/v1/sale-orders', params),
        get: id => $http.get(`/v1/sale-orders/${id}`),
      },
      saleInvoices: {
        getAll: params => $http.get('/v1/sale-invoices', params),
        get: id => $http.get(`/v1/sale-invoices/${id}`),
      },
    }
  },
}
