export default {
  install: app => {
    const requireComponent = require.context(
      // eslint-disable-next-line lines-around-comment
      // The relative path of the components folder
      '@/components',

      // Whether or not to look in subfolders
      true,

      // The regular expression used to match base component filenames
      // /Base[A-Z]\w+\.(vue|js)$/,
      /\.(vue|js)$/i,
    )

    requireComponent.keys().forEach(fileName => {
      // Get component config
      const componentConfig = requireComponent(fileName)

      // Get PascalCase name of component
      const componentName = fileName

        // Remove the "./" from the beginning
        .replace(/^\.\//, '')

        // Remove the file extension from the end
        .replace(/\.\w+$/, '')

        // Split up kebabs
        .split('-')

        // Upper case
        .map(kebab => kebab.charAt(0).toUpperCase() + kebab.slice(1))

        // Concatenated
        .join('')

      // Register component globally
      app.component(
        componentName,

        // Look for the component options on `.default`, which will
        // exist if the component was exported with `export default`,
        // otherwise fall back to module's root.
        componentConfig.default || componentConfig,
      )
    })
  },
}
