import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VTextField,_vm._g(_vm._b({ref:"inputRef",staticClass:"table-search-field user-search mb-4",attrs:{"placeholder":_vm.placeholder,"clearable":_vm.enableClear},scopedSlots:_vm._u([(_vm.enableShortcut && !_vm.search)?{key:"append",fn:function(){return [_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',{staticClass:"icon-container grey rounded cursor-normal",class:_vm.$vuetify.theme.dark ? 'darken-2' : 'lighten-2'},[_c(VIcon,_vm._g({attrs:{"dark":_vm.$vuetify.theme.dark,"size":"18"}},on),[_vm._v(" "+_vm._s(_vm.icons.mdiSlashForward)+" ")])],1)]}}],null,false,2253558364)},[_c('span',[_vm._v("Press "),_c('strong',[_vm._v("/")]),_vm._v(" to focus the search input")])])]},proxy:true}:null],null,true),model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}},'v-text-field',_vm.$attrs,false),Object.assign({}, _vm.$listeners,
    {input: _vm.debounceSearch})))}
var staticRenderFns = []

export { render, staticRenderFns }