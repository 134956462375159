export default {
  namespaced: true,
  state: {
    loading: true,
    scripts: {},
  },
  getters: {
    loading: state => state.loading,
    scripts: state => state.scripts,
    entity: state => entity => state.scripts[entity] || null,
    executing: state => Object.entries(state.scripts).find(({ 1: script }) => script.loading) || false,
  },
  mutations: {
    SET_LOADING(state, payload) {
      state.loading = payload
    },
    SET_SCRIPTS(state, payload) {
      state.scripts = payload
    },
    REFRESH_SCRIPTS(state, payload) {
      state.scripts = Object.fromEntries(
        Object.entries(state.scripts).map(({ 0: entity, 1: script }) => [
          entity,
          { ...script, loading: payload[entity] },
        ]),
      )
    },
    RUN_SCRIPT(state, payload) {
      state.scripts = {
        ...state.scripts,
        [payload]: {
          ...state.scripts[payload],
          loading: true,
        },
      }
    },
    STOP_SCRIPT(state, payload) {
      state.scripts = {
        ...state.scripts,
        [payload]: {
          ...state.scripts[payload],
          loading: false,
        },
      }
    },
  },
  actions: {
    fetchScripts({ commit }) {
      commit('SET_LOADING', true)

      const { _vm: vm } = this
      vm.$http
        .get('/v1/settings/import')
        .then(res => {
          commit('SET_SCRIPTS', res.data.data.scripts)
        })
        .finally(() => commit('SET_LOADING', false))
    },

    healthCheck({ commit }) {
      const { _vm: vm } = this

      return new Promise((resolve, reject) => {
        vm.$http
          .get('/v1/settings/import/health-check')
          .then(res => {
            if (res?.data?.data?.scripts) {
              commit('REFRESH_SCRIPTS', res.data.data.scripts)
            }

            resolve(res)
          })
          .catch(err => reject(err))
      })
    },

    runScript({ commit, getters }, payload) {
      if (getters.loading) return new Promise(reject => reject())

      commit('RUN_SCRIPT', payload)

      const { _vm: vm } = this

      return new Promise((resolve, reject) => {
        vm.$http
          .post('/v1/settings/import/run-script', {
            entity: payload,
          })
          .then(res => {
            if (res?.data?.data?.scripts) {
              commit('REFRESH_SCRIPTS', res.data.data.scripts)
            }
            resolve(res)
          })
          .catch(err => reject(err))
          .finally(() => commit('STOP_SCRIPT', payload))
      })
    },

    stopScript({ commit }, payload) {
      commit('STOP_SCRIPT', payload)
    },
  },
}
