import path from 'path'

// eslint-disable-next-line import/prefer-default-export
export const debounce = (func, wait) => {
  let timeout

  // eslint-disable-next-line func-names, space-before-function-paren
  return function(...args) {
    const context = this
    if (timeout) clearTimeout(timeout)
    timeout = setTimeout(() => {
      timeout = null
      func.apply(context, args)
    }, wait)
  }
}

export const basename = url => path.basename(url)
