<template>
  <v-text-field
    ref="inputRef"
    v-model="search"
    v-bind="$attrs"
    :placeholder="placeholder"
    class="table-search-field user-search mb-4"
    :clearable="enableClear"
    v-on="{
      ...$listeners,
      input: debounceSearch
    }"
  >
    <template
      v-if="enableShortcut && !search"
      #append
      class="mt-0"
    >
      <v-tooltip bottom>
        <template #activator="{on}">
          <div
            class="icon-container grey rounded cursor-normal"
            :class="$vuetify.theme.dark ? 'darken-2' : 'lighten-2'"
          >
            <v-icon
              :dark="$vuetify.theme.dark"
              size="18"
              v-on="on"
            >
              {{ icons.mdiSlashForward }}
            </v-icon>
          </div>
        </template>
        <span>Press <strong>/</strong> to focus the search input</span>
      </v-tooltip>
    </template>
  </v-text-field>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import { ref, computed } from '@vue/composition-api'
import { mdiClose, mdiSlashForward } from '@mdi/js'
import { useCoreUtils } from '@/@core/utils/utils'

export default {
  model: {
    prop: 'search',
    event: 'input',
  },

  props: {
    search: {
      type: String,
      default: () => '',
    },

    placeholder: {
      type: String,
      default: () => 'Search',
    },

    enableShortcut: {
      type: Boolean,
      default: () => true,
    },

    enableClear: {
      type: Boolean,
      default: () => true,
    },
  },

  mounted() {
    window.addEventListener('keypress', this.onKeyPress)
  },

  unmounted() {
    window.removeEventListener('keypress', this.onKeyPress)
  },

  destroyed() {
    window.removeEventListener('keypress', this.onKeyPress)
  },

  methods: {
    onKeyPress(e) {
      if (e.key !== '/' || !this.inputRef) return
      if (this.inputRef?.isFocused) return

      e.preventDefault()
      e.stopPropagation()
      this.inputRef.focus()
    },
  },

  setup(props, { emit }) {
    const { debounce } = useCoreUtils()
    const inputRef = ref(null)

    const q = ref(props.search || '')

    const clearSearch = () => emit('input', '')

    return {
      q,
      inputRef,
      icons: { mdiClose, mdiSlashForward },

      clearSearch,
      debounceSearch: debounce(val => emit('input', val), 750),
    }
  },
}
</script>

<style lang="scss">
.v-text-field.table-search-field[dense] {
  .v-input__control {
    .v-input__slot {
      .v-text-field__slot ~ .v-input__append-inner {
        height: 100%;
        margin-top: 0;
        display: flex;
        align-self: center;
      }

      .v-input__append-inner {
        .icon-container {
          width: 20px;
          height: 20px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .v-input__icon {
          &.v-input__icon--clear {
            button.v-icon {
              svg {
                margin-right: 0;
              }
            }
          }
        }
      }
    }
  }
}
</style>
