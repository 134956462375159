<template>
  <div>
    <v-snackbar
      v-for="(snack, i) in snacks"
      :key="snack.id"
      :right="true"
      :bottom="true"
      :dark="isDark"
      :light="!isDark"
      :color="snack.color"
      :value="snack.message"
      :text="snack.text || false"
      :timeout="snack.timeout || 3000"
      :multi-line="snack.multiline || false"
      :style="{ 'bottom': `${(i * 64)}px` }"
      @input="e => snack && removeMessage(snack.id)"
    >
      {{ snack.message }}

      <template v-slot:action="{ attrs }">
        <v-btn
          v-if="snack.disposable"
          icon
          small
          v-bind="attrs"
          @click="removeMessage(snack.id)"
        >
          <v-icon size="20">
            {{ icons.mdiClose }}
          </v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { mdiClose } from '@mdi/js'
import useAppConfig from '@core/@app-config/useAppConfig'
import { computed, getCurrentInstance } from '@vue/composition-api'

export default {
  setup() {
    const { isDark } = useAppConfig()
    const vm = getCurrentInstance().proxy

    const snacks = computed(() => vm.$store.getters['snackbar/snacks'])
    const removeMessage = id => vm.$store.dispatch('snackbar/removeMessage', id)

    return {
      isDark,

      snacks,
      removeMessage,

      icons: {
        mdiClose,
      },
    }
  },
}
</script>
