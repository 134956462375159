export default [
  {
    path: '/login',
    name: 'auth-login',
    component: () => import('@/views/Login.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
      redirectIfLoggedIn: true,
    },
  },

  // {
  //   path: '/register',
  //   name: 'auth-register',
  //   component: () => import('@/views/Register.vue'),
  //   meta: {
  //     layout: 'blank',
  //     resource: 'Public',
  //     redirectIfLoggedIn: true,
  //   },
  // },
  // {
  //   path: '/forgot-password',
  //   name: 'auth-forgot-password',
  //   component: () => import('@/views/ForgotPassword.vue'),
  //   meta: {
  //     layout: 'blank',
  //     resource: 'Public',
  //     redirectIfLoggedIn: true,
  //   },
  // },
]
