export default [
  {
    path: '/sale-invoices',
    name: 'sale-invoice-list',
    component: () => import('@/views/saleInvoices/sale-invoice-list/SaleInvoiceList.vue'),
    meta: {
      layout: 'content',
      resource: 'SaleInvoice',
      action: 'view',
    },
  },
  {
    path: '/sale-invoices/:id',
    name: 'sale-invoice-view',
    component: () => import('@/views/saleInvoices/sale-invoice-view/SaleInvoiceView.vue'),
    meta: {
      layout: 'content',
      resource: 'SaleInvoice',
      action: 'view',
    },
  },
]
