import store from '@/store'
import axios from 'axios'
import Vue from 'vue'

const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  baseURL: process.env.API_URL,

  // timeout: 9000,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
})

axiosIns.interceptors.request.use(
  config => {
    const accessToken = localStorage.getItem('accessToken')

    if (accessToken) {
      // eslint-disable-next-line no-param-reassign
      if (accessToken.startsWith('Bearer ')) config.headers.Authorization = accessToken
      // eslint-disable-next-line no-param-reassign
      else config.headers.Authorization = `Bearer ${accessToken}`
    }

    return config
  },
  error => Promise.reject(error),
)

axiosIns.interceptors.response.use(
  res => {
    // console.log(res)

    if (res?.headers?.['content-type']?.includes('application/json')) {
      const { data = {} } = res
      if (data.message) {
        Vue.prototype.$snackbar.info({
          message: data.message,
        })
      }
    }

    return res
  },
  err => {
    console.dir(err)

    const { response: { status = 500, data = {} } = {} } = err

    if (data.message) {
      Vue.prototype.$snackbar.error({
        message: data.message,
      })
    }

    if (status === 401) {
      store.dispatch('auth/logout')
    } else if (status === 403) {
      //
    } else if (status === 422 || data?.status === 'fail') {
      return Promise.reject(data)
    } else if (data?.status === 'error') {
      return Promise.reject(data)
    }

    return Promise.reject(err)
  },
)

Vue.prototype.$http = axiosIns

export default axiosIns
