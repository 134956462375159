import * as string from './string'
import * as datetime from './datetime'
import * as reactivity from './reactivity'

import * as helpers from './helpers'

export const useCoreUtils = () => ({
  ...string,
  ...helpers,
  ...datetime,
  ...reactivity,
})

export const _ = null
