<template>
  <!-- table -->
  <!-- v-on="$listeners" -->
  <v-data-table
    :footer-props="$attrs['footer-props'] || {
      'items-per-page-options': [5, 10, 25, 50, 100]
    }"

    v-bind="$attrs"
    :options="options"
    :server-items-length="meta.total"

    v-on="{
      ...$listeners,
      'update:options': handleOptions
    }"
  >
    <template v-slot:top>
      <table-search
        v-model="search"
        dense
        full-width
        hide-details
        class="pt-2 pb-1"
        :class="searchContainerClass"
        @input="$emit('update:search', $event)"
      >
      </table-search>
    </template>

    <template
      v-for="(index, name) in $slots"
      v-slot:[name]
    >
      <slot :name="name" />
    </template>

    <template
      v-for="(index, name) in $scopedSlots"
      v-slot:[name]="data"
    >
      <slot
        :name="name"
        v-bind="data"
      ></slot>
    </template>
  </v-data-table>
</template>

<script>
// eslint-disable-next-line no-unused-vars, object-curly-newline
import { ref, watch, computed, reactive } from '@vue/composition-api'

import { isEqual } from 'lodash'

export default {
  props: {
    meta: {
      type: Object,
      default: () => ({}),
    },
    options: {
      type: Object,
      default: () => ({}),
    },
    search: {
      type: String,
      default: () => '',
    },
    searchContainerClass: {
      type: String,
      default: () => 'mx-3 px-2',
    },
  },
  setup(props, ctx) {
    const tableOptions = computed({
      get: () => props.options,
      // eslint-disable-next-line no-unused-vars
      set: val => {
        // ctx.emit('update:options', val)
      },
    })

    const handleOptions = o => {
      if (!isEqual(props.options, o)) {
        ctx.emit('update:options', o)
      }
    }

    return {
      tableOptions,
      handleOptions,

      log: v => console.log(v),
    }
  },
}
</script>
