export default [
  // {
  //   path: '/profile',
  //   name: 'user-profile',
  //   component: () => import('@/views/users/user-view/UserView.vue'),
  //   meta: {
  //     layout: 'content',
  //   },
  // },
  {
    path: '/users',
    name: 'user-list',
    component: () => import('@/views/users/user-list/UserList.vue'),
    meta: {
      layout: 'content',
      resource: 'User',
      action: 'view',
    },
  },

  // {
  //   path: '/users/:id',
  //   name: 'user-view',
  //   component: () => import('@/views/users/user-view/UserView.vue'),
  //   meta: {
  //     layout: 'content',
  //     resource: 'User',
  //     action: 'view',
  //   },
  // },
]
