// eslint-disable-next-line object-curly-newline
import { mdiFileDocumentOutline, mdiReceipt } from '@mdi/js'

export default [
  {
    title: 'Sale Orders',
    to: 'sale-order-list',
    icon: mdiFileDocumentOutline,
  },
  {
    title: 'Sale Invoices',
    to: 'sale-invoice-list',
    icon: mdiReceipt,
  },
]
