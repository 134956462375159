import { v5 as uuidv5 } from 'uuid'

export default {
  namespaced: true,
  state: {
    snacks: [],
  },
  getters: {
    snacks: state => state.snacks,
    snacksById: state => id => state.snacks.find(snack => snack.id === id) || null,
  },
  mutations: {
    PUSH_SNACK(state, payload) {
      const snack = {
        id: uuidv5(payload.message, uuidv5.URL),
        timeout: 3000,
        disposable: true,
        ...payload,
      }
      if (!state.snacks.find(s => JSON.stringify(s) === JSON.stringify(snack))) {
        state.snacks.push(snack)
      }
    },
    REMOVE_SNACK(state, id) {
      state.snacks = state.snacks.filter(snack => snack.id !== id)
    },
    REMOVE_ALL_SNACKS(state) {
      state.snacks = []
    },
  },
  actions: {
    showMessage({ commit }, payload) {
      commit('PUSH_SNACK', payload)
    },
    removeMessage({ commit }, payload) {
      commit('REMOVE_SNACK', payload)
    },
    info({ commit }, payload) {
      commit('PUSH_SNACK', {
        ...payload,
        color: 'info',
      })
    },
    success({ commit }, payload) {
      commit('PUSH_SNACK', {
        ...payload,
        color: 'success',
      })
    },
    error({ commit }, payload) {
      commit('PUSH_SNACK', {
        ...payload,
        color: 'error',
      })
    },
    warning({ commit }, payload) {
      commit('PUSH_SNACK', {
        ...payload,
        color: 'warning',
      })
    },
  },
}
