// eslint-disable-next-line object-curly-newline
import { mdiCogs } from '@mdi/js'

export default [
  {
    subheader: 'SETTINGS',
    resource: '*',
  },
  {
    title: 'General',
    to: 'settings',
    icon: mdiCogs,
    resource: '*',
  },
]
