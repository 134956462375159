// eslint-disable-next-line object-curly-newline
import {
  mdiFrequentlyAskedQuestions,
  mdiNewspaperVariantMultiple,
  mdiCalendarClockOutline,
  mdiInformation,
  mdiLibrary,
  mdiAccountTieVoice,
  mdiTagMultipleOutline,
} from '@mdi/js'

export default [
  {
    subheader: 'CONTENT',
    resource: 'Public',
    action: 'read',
  },
  {
    title: 'Events',
    to: 'event-list',
    icon: mdiCalendarClockOutline,
    resource: 'Event',
    action: 'view',
  },
  {
    title: 'News',
    icon: mdiNewspaperVariantMultiple,
    resource: 'News',
    action: 'view',
    children: [
      {
        title: 'Articles',
        icon: mdiNewspaperVariantMultiple,
        to: 'news-list',
        resource: 'News',
        action: 'view',
      }, {
        title: 'Categories',
        icon: mdiTagMultipleOutline,
        to: 'news-category-list',
        resource: 'NewsCategory',
        action: 'view',
      },
    ],
  },
  {
    title: 'Tips',
    to: 'tip-list',
    icon: mdiInformation,
    resource: 'Tip',
    action: 'view',
  },
  {
    title: 'Academies',
    to: 'academy-list',
    icon: mdiLibrary,
    resource: 'Academy',
    action: 'view',
  },
  {
    title: 'Campaigns',
    to: 'campaign-list',
    icon: mdiAccountTieVoice,
    resource: 'Campaign',
    action: 'view',
  },
  {
    title: 'Discoveries',
    to: 'discovery-list',
    icon: mdiFrequentlyAskedQuestions,
    resource: 'Discovery',
    action: 'view',
  },
]
