// eslint-disable-next-line no-unused-vars, object-curly-newline
import { reduceReactivity } from '@core/utils/reactivity'
import { markRaw, readonly, unref } from '@vue/composition-api'
import { isEqual } from 'lodash'

export default {
  namespaced: true,
  state: {
    q: '',
    meta: {
      page: 1,
      total: 0,
    },
    items: [],
    loading: true,
    options: {
      sortBy: ['number'],
      sortDesc: [true],
      itemsPerPage: 50,
    },
    filters: {
      role: null,
    },
  },
  getters: {
    meta: state => state.meta,
    search: state => state.q,
    options: state => state.options,
    filters: state => state.filters,

    items: state => state.items,
    loading: state => state.loading,
    getItemById: state => id => state.items.find(user => user.id === id),

    requestMeta: state =>
      unref({
        ...Object.entries({ ...readonly(state.options) }).reduce(reduceReactivity, markRaw({})),
        q: readonly(state.q),
        filters: Object.entries({ ...readonly(state.filters) }).reduce(reduceReactivity, markRaw({})),
      }),
  },
  mutations: {
    SET_LOADING(state, payload) {
      state.loading = payload
    },
    SET_ITEMS(state, payload) {
      state.items = payload
    },
    SET_META(state, payload) {
      state.meta = payload
    },
    SET_FILTERS(state, payload) {
      state.filters = payload
    },
    SET_OPTIONS(state, payload) {
      if (!isEqual(state.options, payload)) {
        state.options = payload
      }
    },
    SET_SEARCH(state, payload) {
      state.q = payload
      if (state.options?.page !== 1) {
        state.options = {
          ...state.options,
          page: 1,
        }
      }
    },
  },
  actions: {
    setMeta({ commit }, payload) {
      commit('SET_META', payload)
    },

    setFilters({ commit, dispatch }, payload) {
      commit('SET_FILTERS', payload)
      dispatch('fetchItems')
    },

    setSearch({ commit, dispatch }, payload) {
      commit('SET_SEARCH', payload)
      dispatch('fetchItems')
    },

    setOptions({ commit, dispatch }, payload) {
      commit('SET_OPTIONS', payload)
      dispatch('fetchItems')
    },

    fetchItems({ commit, getters }, meta) {
      commit('SET_LOADING', true)

      const { _vm: vm } = this
      vm.$http
        .get('/v1/sale-invoices', {
          params: meta || getters.requestMeta,
        })
        .then(res => {
          commit('SET_META', res.data.data.meta)
          commit('SET_ITEMS', res.data.data.saleInvoices)

          // commit('SET_OPTIONS', res.data.data.options)
          // commit('SET_FILTERS', res.data.data.filters)
        })
        .finally(() => commit('SET_LOADING', false))
    },
  },
}
