// eslint-disable-next-line object-curly-newline
import { mdiFileDocumentOutline, mdiReceipt } from '@mdi/js'

export default [
  {
    subheader: 'SALES',
    resource: 'Public',
    action: 'read',
  },
  {
    title: 'Orders',
    to: 'sale-order-list',
    icon: mdiFileDocumentOutline,
    resource: 'SaleOrder',
    action: 'view',
  },
  {
    title: 'Invoices',
    to: 'sale-invoice-list',
    icon: mdiReceipt,
    resource: 'SaleInvoice',
    action: 'view',
  },
]
