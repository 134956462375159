import Sortable from 'sortablejs'

export default {
  install: Vue => {
    Vue.directive('sortableDataTable', {
      // eslint-disable-next-line no-unused-vars
      bind(el, binding, vnode) {
        const options = {
          forceFallback: true,
          animation: 150,
          onUpdate(event) {
            vnode.child.$emit('sorted', event)
          },
        }

        Sortable.create(el.getElementsByTagName('tbody')[0], options)
      },
    })
  },
}
