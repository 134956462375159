<script>
import { computed, onMounted, ref } from '@vue/composition-api'
import {
  Doc,
  Text,

  // // Paragraph,
  // // Heading,
  Bold,

  // Italic,
  // Strike,
  // Underline,
  // Link,
  // Image,
  // Blockquote,
  // ListItem,
  // BulletList, // use with ListItem
  // OrderedList, // use with ListItem
  // TodoItem,
  // TodoList, // use with TodoItem
  // TextAlign,
  // Indent,
  // HorizontalRule,
  // HardBreak,
  History,

  // LineHeight,
  // Iframe,
  // CodeBlock,
  // TrailingNode,
  // Table, // use with TableHeader, TableCell, TableRow
  // TableHeader,
  // TableCell,
  // TableRow,
  // FormatClear,
  // TextColor,
  // TextHighlight,
  Preview,

  // Print,
  Fullscreen,
  CodeView,
  SelectAll,
} from 'element-tiptap'
import {

  // Heading,
  Paragraph,
} from '@core/utils/tiptap'
import createFilePondServer from '@core/utils/filePond'
import codemirror from 'codemirror'
import 'codemirror/lib/codemirror.css' // import base style
import 'codemirror/mode/xml/xml' // language
import 'codemirror/addon/selection/active-line' // require active-line
import 'codemirror/addon/edit/closetag' // autoCloseTags

export default {
  name: 'ElementTiptap',

  inheritAttrs: false,

  props: {
    errorMessages: {
      type: Array,
      default: () => ([]),
    },

    label: {
      type: String,
      default: () => (''),
    },

    rules: {
      type: Array,
      default: () => ([]),
    },

    value: {
      type: String,
      default: '',
    },
  },

  emits: ['input'],

  setup(props, { emit }) {
    const editorRef = ref(null)

    const content = ref('')

    const isEmpty = computed(() => (editorRef.value.editor.state.doc.textContent.length ?? 0) < 1)

    const filePondServer = createFilePondServer({
      disk: 'news',
    })

    const extensions = ref([
      new Doc(),
      new Text(),
      new Paragraph({
        fontFamily: {
          default: 'Avenir',
        },
      }),

      // new Heading({
      //   level: 6,
      //   fontFamily: {
      //     default: 'Avenir',
      //   },
      // }),
      new Bold({ bubble: true }),

      // new Underline({ bubble: true }),
      // new Italic({ bubble: true }),
      // new Strike({ bubble: true }),
      // new Link({ bubble: true }),
      // new Image({
      //   uploadRequest: file => new Promise((res, rej) =>
      //     filePondServer.process(
      //       'image',
      //       file,
      //       {},
      //       uuid => res(filePondServer.files.value[uuid].fileUrl),
      //       err => {
      //         console.error(err)

      //         // filePondServer.revert()
      //         rej(err)
      //       },
      //       progress => console.log(progress),
      //       abort => console.warn(abort),
      //     )),
      // }),

      // new Blockquote(),
      // new TextAlign(),

      // new ListItem(),
      // new BulletList({ bubble: true }),
      // new OrderedList({ bubble: true }),
      // new TodoItem(),
      // new TodoList(),
      // new Indent(),
      // new HardBreak(),
      // new HorizontalRule({ bubble: true }),

      // new LineHeight(),
      // new Iframe(),
      // new CodeBlock(),
      // new TrailingNode(),
      // new Table(), // use with TableHeader, TableCell, TableRow
      // new TableHeader(),
      // new TableCell(),
      // new TableRow(),
      // new FormatClear(),
      // new TextColor(),
      // new TextHighlight(),
      new Preview(),

      // new Print(),
      new SelectAll(),

      new History(),
      new Fullscreen(),
      new CodeView({
        codemirror,
        codemirrorOptions: {
          styleActiveLine: true,
          autoCloseTags: true,
        },
      }),
    ])

    onMounted(() => {
      content.value = props.value
    })

    // eslint-disable-next-line no-unused-vars
    const onInit = ({ editor }) => {
      // console.warn(editor)
      // console.error(editorRef.value)
    }

    const onUpdate = val => emit('input', !isEmpty.value ? val : '')

    // watch(() => editorRef.value && editorRef.value.isCodeViewMode, newVal => {
    //   emit('isCodeViewMode', newVal)
    // })

    return {
      content,
      extensions,
      filePondServer,

      editorRef,

      isEmpty,

      onInit,
      onUpdate,
    }
  },
}
</script>

<template>
  <v-input
    class="el-tiptap--wrapper v-input--is-focused"
    :rules="rules"
    :active="true"
    :label="label"
    :value="value"
    :style="{
      transformOrigin: 'top left'
    }"
    :error-messages="errorMessages"
  >
    <template #default>
      <div class="v-text-field__slot">
        <!--        <label-->
        <!--          v-if="$slots.label"-->
        <!--          :for="$attrs['name'] || ''"-->
        <!--          class="v-label v-label&#45;&#45;active position-absolute"-->
        <!--          :class="{-->
        <!--            'theme&#45;&#45;dark': $vuetify.theme.isDark,-->
        <!--            'theme&#45;&#45;light': !$vuetify.theme.isDark,-->
        <!--            'error&#45;&#45;text': errorMessages.length,-->
        <!--          }"-->
        <!--          :style="{-->
        <!--            top: 0,-->
        <!--            left: 0,-->
        <!--            right: 'auto',-->
        <!--            transformOrigin: 'top left'-->
        <!--          }"-->
        <!--        >-->
        <!--          <slot name="label" />-->
        <!--        </label>-->

        <v-sheet
          class="w-100"
          rounded
          elevation="4"
        >
          <v-card elevation="4">
            <!--        <label-->
            <!--          v-if="$slots.label"-->
            <!--          :for="$attrs['name'] || ''"-->
            <!--          class="v-label v-label&#45;&#45;active position-absolute"-->
            <!--          :class="{-->
            <!--            'theme&#45;&#45;dark': $vuetify.theme.isDark,-->
            <!--            'theme&#45;&#45;light': !$vuetify.theme.isDark,-->
            <!--          }"-->
            <!--          :style="{-->
            <!--            top: 0,-->
            <!--            left: 0,-->
            <!--            right: 'auto',-->
            <!--            transformOrigin: 'top left'-->
            <!--          }"-->
            <!--        >-->
            <!--          <slot name="label" />-->
            <!--        </label>-->

            <el-tiptap
              ref="editorRef"
              :extensions="extensions"
              :content="content"
              v-bind="$attrs"
              @onInit="onInit"
              @onUpdate="onUpdate"
              v-on="{
                ...$on,
                ...$listeners,
              }"
            />
          </v-card>
        </v-sheet>
      </div>
    </template>
  </v-input>
</template>

<style lang="scss">
.el-tiptap--wrapper {
  > .v-input__control {
    > .v-input__slot {
      align-items: start;
      flex-direction: column;

      > .v-label {
        max-width: 133%;
        transform: translateY(-8px) scale(0.75);
        pointer-events: auto;
      }

      > .v-text-field__slot {
        display: flex;
        flex: 1 1 auto;
        position: relative;
        width: 100%;
      }
    }
  }
}
</style>

<style lang="scss">
$primary-color: var(--v-primary-base);
$secondary-color: var(--v-secondary-base);

@include theme(v-card) using ($material) {
  $background-color: map-get($material, 'cards');
  $border-color: map-get($material, 'cards');

  $color: map-deep-get($material, 'text', 'secondary');
  $hover-color: map-deep-get($material, 'text', 'primary');

  background-color: $background-color;
  border-color: $border-color;
  color: $color;

  .el-tiptap-editor__menu-bar {
    background-color: $background-color;
    border: 1px solid $border-color;
  }

  .el-tooltip.el-tiptap-editor__command-button > svg {
    color: $color;
    color: currentColor;
  }
  .el-tooltip.el-tiptap-editor__command-button > i {
    color: $color;
    color: currentColor;
  }

  .el-tiptap-editor > .el-tiptap-editor__content {
    background-color: $background-color;
    border: 1px solid $border-color;
    color: $color;
  }
  .el-tiptap-editor__menu-bar:before {
    background-color: $background-color;
  }
  .el-tiptap-editor__footer {
    background-color: $background-color;
    color: $color;
    border: 1px solid $border-color;
  }

  .el-tiptap-editor__command-button {
    color: $color;
  }

  .el-tiptap-editor__command-button--active {
    background-color: $primary-color;
    border: 1px solid $border-color;

    background-color: $background-color;
    color: $primary-color;
  }

  .el-tiptap-editor__command-button:hover {
    background-color: $background-color;
    color: $color;
    color: $primary-color;
  }

  .el-tiptap-editor__menu-bubble {
    background-color: $background-color;
    color: $color;
  }

  .el-dropdown {
    color: $color;
  }

  @at-root {
  .el-popover {
    background: $background-color;
    border: 1px solid $border-color;
    color: $color;

    .el-tooltip.el-tiptap-editor__command-button {
      color: $color;

      > svg, i {
        // color: $color;
        color: currentColor;
      }

      &:hover {
        background-color: $background-color;
        color: $primary-color;
      }
    }

    &[x-placement^='bottom'] .popper__arrow {
      border-bottom-color: $border-color;
    }

    .el-tiptap-editor__content > div {
      height: 100%;
    }

    .el-tiptap-popper__menu__item {
      color: $color;
    }
    .el-tiptap-popper__menu__item__separator {
      border-top: 1px solid $border-color;
    }

    &.el-popper.el-tiptap-image-popper {
      background: $background-color;
    }
  }

  .el-dropdown-menu {
    background-color: $background-color;
    color: $color;
    border: 1px solid $border-color;

    &.el-tiptap-dropdown-menu {
      background-color: $background-color;

      .el-tiptap-dropdown-menu__item--active {
        // background-color: $hover-color;
        // color: $color;
        background-color: $background-color;
        color: $primary-color;
      }
      .el-tiptap-dropdown-menu__item {
        color: $color;

        &:hover {
          background-color: $background-color;
          color: $primary-color;
        }
      }
    }
  }

  .el-dialog {
    background: $background-color;

    .el-dialog__title,
    .el-form-item__label {
      color: $color;
    }

    &.el-tiptap-edit-link-dialog {
      min-width: 175px;
    }

    .el-button {
      &--primary {
        color: $primary-color;
      }
      &--default {
        color: $secondary-color;
      }
    }
  }

  .el-message-box {
    background: $background-color;

    &__title {
      color: $color;
    }

    .el-button {
      &--primary {
        color: $primary-color;
      }
      &--default {
        color: $secondary-color;
      }
    }
  }

  .el-button {
    border: 1px solid $border-color;

    &.el-button--primary {
      color: $color !important;
      background-color: $primary-color !important;

      &:hover {
        background: $primary-color;
      }

      &.el-button--small.is-round {
        color: $color !important;
        background-color: $primary-color !important;
      }
    }
  }

  .el-input {
    > input {
      background-color: #FFF;
      color: $secondary-color;
      border: 1px solid $border-color;
      &:focus {
        border: 1px solid $border-color;
      }
    }
    &__inner {
      color: $color;
    }
  }

  .el-checkbox {
    &__label {
      color: $color;
    }
    &__input.is-checked + &__label {
      color: $color;
    }
    &__inner {
      color: $primary-color;
    }
    &__input {
      &.is-checked {
        + .el-checkbox__label {
          color: $color;
        }
        .el-checkbox__inner {
          color: $primary-color;
        }
      }
    }
  }
  }

  .el-tiptap-editor__content > div {
    height: 100%;
  }

  .table-grid-size-editor__cell {
    background: $background-color;
  }
  .table-grid-size-editor__cell--selected .table-grid-size-editor__cell__inner {
    background-color: #ecf5ff;
    border-color: #409eff;
  }

  .el-tiptap-editor__content .image-resizer__handler {
    background-color: $primary-color;
  }
  .el-tiptap-editor__content .image-resizer {
    border-color: $primary-color;
  }
  .el-input.is-disabled .el-input__inner {
    background-color: $background-color;
    border-color: $border-color;

    background-color: $background-color;
    border-color: $border-color;
  }

  // Codemirror Style overrides
  // Code-view left number bar
  .CodeMirror-gutters {
    background-color: $background-color;
    border-right: 1px solid $border-color;
  }
  .CodeMirror-linenumber {
    color: $primary-color;
  }
  .CodeMirror-lines {
    background-color: $background-color;
    color: $color;

    .CodeMirror-cursors {
      @at-root {
        .theme--light {
          .CodeMirror-cursor {
            border-left-color: $primary-color;
          }
        }
        .theme--dark {
          .CodeMirror-cursor {
            border-left-color: $color;
          }
        }
      }
    }

    .CodeMirror-code {
      .CodeMirror-activeline {
        .CodeMirror-activeline-background {
          background: $secondary-color;
          opacity: .235;
        }
      }
    }
  }
  .CodeMirror-scroll {
    background-color: $background-color;
  }
  .CodeMirror-line {
    span &::selection {
      background: $secondary-color;
      color: black;
    }
    span[role="presentation"] {
      color: var(--v-secondary-base);

      @at-root {
        .cm-s-default {
          .cm-tag {
            color: var(--v-success-base);
          }
          .cm-attribute {
            color: var(--v-accent-base);
          }
          .cm-string {
            color: var(--v-error-base);
          }
        }
      }
    }
  }
}
</style>
