import router from '@/router'
import appConfigStoreModule from '@core/@app-config/appConfigStoreModule'
import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'
import app from './app'
import auth from './auth'
import customers from './customers'
import itemCategories from './itemCategories'
import items from './items'
import saleInvoices from './saleInvoices'
import saleOrders from './saleOrders'
import snackbar from './snackbar'
import users from './users'
import events from './events'
import news from './news'
import newsCategories from './newsCategories'
import tips from './tips'
import discoveries from './discoveries'
import academies from './academies'
import campaigns from './campaigns'
import settings from './settings'

Vue.use(Vuex)

const didVersionChange = () => {
  console.info({
    processVersion: process.env.VERSION,
    storageVersion: localStorage.getItem('appVersion'),
  })

  return process.env.VERSION !== localStorage.getItem('appVersion')
}

const clearPersistedState = (force = false) => {
  if (force || didVersionChange()) {
    console.error('CLEARING VUEX STORAGE')
    localStorage.removeItem('vuex')

    // localStorage.removeItem('userData')
    // localStorage.removeItem('userAbility')
    // localStorage.removeItem('accessToken')
  }

  if (process.env.VERSION) {
    localStorage.setItem('appVersion', process.env.VERSION)
  }
}

const vuexLocal = new VuexPersistence({
  supportCircular: true,
  storage: window.localStorage,
})

export default new Vuex.Store({
  plugins: [vuexLocal.plugin],
  state: {},
  mutations: {
    AFTER_LOGOUT() {
      if (router?.history?.current?.name !== 'auth-login') {
        router.replace({ name: 'auth-login' })
      }
    },
  },
  actions: {
    clearPersistedState() {
      clearPersistedState()
    },
  },
  modules: {
    appConfig: appConfigStoreModule,
    app,
    snackbar,
    auth,
    users,
    events,
    customers,
    itemCategories,
    items,
    saleOrders,
    saleInvoices,

    news,
    newsCategories,
    tips,
    academies,
    campaigns,
    discoveries,

    settings,
  },
})
