export default [
  {
    path: '/customers',
    name: 'customer-list',
    component: () => import('@/views/customers/customer-list/CustomerList.vue'),
    meta: {
      layout: 'content',
      resource: 'Customer',
      action: 'view',
    },
  },
  {
    path: '/customers/:id',
    name: 'customer-view',
    component: () => import('@/views/customers/customer-view/CustomerView.vue'),
    meta: {
      layout: 'content',
      resource: 'Customer',
      action: 'view',
    },
  },
]
