export default [
  //
  //* ——— Invoice ——————————————————
  //

  // {
  //   path: '/invoice/list',
  //   name: 'apps-invoice-list',
  //   component: () => import('@/views/invoice/invoice-list/InvoiceList.vue'),
  //   meta: {
  //     layout: 'content',
  //   },
  // },
  // {
  //   path: '/invoice/preview/:id',
  //   name: 'apps-invoice-preview',
  //   component: () => import('@/views/invoice/invoice-preview/InvoicePreview.vue'),
  //   meta: {
  //     layout: 'content',
  //   },
  // },
  // {
  //   path: '/invoice/add/',
  //   name: 'apps-invoice-add',
  //   component: () => import('@/views/invoice/invoice-add/InvoiceAdd.vue'),
  //   meta: {
  //     layout: 'content',
  //   },
  // },
  // {
  //   path: '/invoice/edit/:id',
  //   name: 'apps-invoice-edit',
  //   component: () => import('@/views/invoice/invoice-edit/InvoiceEdit.vue'),
  //   meta: {
  //     layout: 'content',
  //   },
  // },

  //
  //* ——— Misc ——————————————————
  //

  {
    path: '/misc/under-maintenance',
    name: 'misc-under-maintenance',
    component: () => import('@/views/miscellaneous/UnderMaintenance.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/misc/not-authorized',
    name: 'misc-not-authorized',
    component: () => import('@/views/miscellaneous/NotAuthorized.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
    },
  },
  {
    path: '/misc/access-control',
    name: 'page-access-control',
    component: () => import('@/views/miscellaneous/AccessControl.vue'),
    meta: {
      layout: 'content',
      resource: 'Demo',
    },
  },
]
