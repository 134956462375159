import { unref } from '@vue/composition-api'

export const toValue = val => typeof val === 'function'
  ? val()
  : unref(val)

// eslint-disable-next-line import/prefer-default-export
export const reduceReactivity = (acc, { 0: key, 1: curr }) => {
  if (!curr || curr === undefined || curr === 'undefined') {
    return unref({ ...acc, ...{ [key]: null } })
  }

  if (Array.isArray(curr)) {
    return unref({ ...acc, ...{ [key]: [...toValue(curr)] } })

    // return unref({ ...acc, ...{ [key]: [...(curr.value || curr)] } })
  }

  if (curr.constructor === Object) {
    return unref({ ...acc, ...{ [key]: { ...toValue(curr) } } })

    // return unref({ ...acc, ...{ [key]: { ...(curr.value || curr) } } })
  }

  return unref({ ...acc, ...{ [key]: curr } })
}
