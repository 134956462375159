// eslint-disable-next-line no-unused-vars, object-curly-newline
// eslint-disable-next-line no-unused-vars
import { reduceReactivity } from '@core/utils/reactivity'
// eslint-disable-next-line no-unused-vars
import { markRaw, readonly, unref } from '@vue/composition-api'
import { isEqual } from 'lodash'

export default {
  namespaced: true,
  state: {
    q: '',
    meta: {
      page: 1,
      total: 0,
    },
    items: [],
    loading: true,
    creating: false,
    options: {
      sortBy: ['name'],
      sortDesc: [true],
      itemsPerPage: 50,
    },
    filters: {
      role: null,
    },
  },
  getters: {
    meta: state => state.meta,
    search: state => state.q,
    options: state => state.options,
    filters: state => state.filters,

    items: state => state.items,
    loading: state => state.loading,
    creating: state => state.creating,
    deleting: state => state.deleting,
    getItemById: state => id => state.items.find(user => user.id === id),

    requestMeta: state =>
      unref({
        ...Object.entries({ ...readonly(state.options) }).reduce(reduceReactivity, markRaw({})),
        q: readonly(state.q),
        filters: Object.entries({ ...readonly(state.filters) }).reduce(reduceReactivity, markRaw({})),
      }),
  },
  mutations: {
    SET_LOADING(state, payload) {
      state.loading = payload
    },
    SET_CREATING(state, payload) {
      state.creating = payload
    },
    SET_DELETING(state, payload) {
      state.deleting = payload
    },
    SET_ITEMS(state, payload) {
      state.items = payload
    },
    SET_META(state, payload) {
      state.meta = payload
    },
    SET_FILTERS(state, payload) {
      state.filters = payload
    },
    SET_OPTIONS(state, payload) {
      if (!isEqual(state.options, payload)) {
        state.options = payload
      }
    },
    SET_SEARCH(state, payload) {
      state.q = payload
      if (state.options?.page !== 1) {
        state.options = {
          ...state.options,
          page: 1,
        }
      }
    },
  },
  actions: {
    setMeta({ commit }, payload) {
      commit('SET_META', payload)
    },

    setFilters({ commit, dispatch }, payload) {
      commit('SET_FILTERS', payload)
      dispatch('fetchItems')
    },

    setSearch({ commit, dispatch }, payload) {
      commit('SET_SEARCH', payload)
      dispatch('fetchItems')
    },

    setOptions({ commit, dispatch }, payload) {
      commit('SET_OPTIONS', payload)
      dispatch('fetchItems')
    },

    fetchItems({ commit, getters }, meta) {
      commit('SET_LOADING', true)

      const { _vm: vm } = this
      vm.$http
        .get('/v1/users', {
          params: meta || getters.requestMeta,
        })
        .then(res => {
          commit('SET_META', res.data.data.meta)
          commit('SET_ITEMS', res.data.data.users)

          // commit('SET_FILTERS', res.data.data.filters)
          // commit('SET_OPTIONS', res.data.data.options)
        })
        .finally(() => commit('SET_LOADING', false))
    },

    create({ commit, dispatch }, formData) {
      commit('SET_CREATING', true)

      const { _vm: vm } = this

      return new Promise((resolve, reject) => {
        vm.$http
          .post('/v1/users/create', formData)
          .then(res => {
            commit('SET_CREATING', false)

            dispatch('fetchItems')

            resolve(res)
          })
          .catch(err => {
            commit('SET_CREATING', false)
            reject(err)
          })
      })
    },

    destroy({ commit, dispatch }, id) {
      commit('SET_DELETING', true)

      const { _vm: vm } = this

      return new Promise((resolve, reject) => {
        vm.$http
          .delete(`/v1/users/${id}`)
          .then(res => {
            commit('SET_DELETING', false)
            dispatch('fetchItems')
            resolve(res?.data?.data?.deleted)
          })
          .catch(err => {
            commit('SET_DELETING', false)
            reject(err)
          })
      })
    },
  },
}
