/* eslint-disable sort-imports */
/* eslint-disable  import/order */
/* eslint sort-imports: ["error"] */
import Vue from 'vue'
import VueCompositionAPI from '@vue/composition-api'
import { i18n } from '@/plugins/i18n'
import ElementUI from 'element-ui'
import { ElementTiptapPlugin } from 'element-tiptap'
import 'element-ui/lib/theme-chalk/index.css'
import 'element-tiptap/lib/index.css'
import '@/styles/styles.scss'
import './plugins/acl'
import './plugins/axios'

import App from './App.vue'

import filters from './plugins/filters'
import snackbar from './plugins/snackbar'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store'
import sortableTable from './plugins/sortable'
import components from './plugins/components'
import repository from './plugins/repository'

console.disableYellowBox = true

Vue.config.silent = true
Vue.config.productionTip = false

Vue.use(VueCompositionAPI)
Vue.use(ElementUI)
Vue.use(ElementTiptapPlugin)

Vue.use(repository)

Vue.use(components)
Vue.use(filters)
Vue.use(snackbar, { store })
Vue.use(sortableTable)

new Vue({
  router,
  store,
  i18n,
  vuetify,
  watch: {
    '$vuetify.theme.dark': {
      // eslint-disable-next-line no-unused-vars
      handler: (newVal, oldVal) => {
        switch (newVal) {
          case true:
            document.querySelector('body').classList.add('theme--dark')
            document.querySelector('body').classList.remove('theme--light')
            break

          case false:
            document.querySelector('body').classList.remove('theme--dark')
            document.querySelector('body').classList.add('theme--light')
            break

          default: console.log(newVal)
        }
      },
      deep: true,
    },
  },
  async beforeCreate() {
    if (this.$store) {
      this.$store.dispatch('clearPersistedState')

      if (localStorage.getItem('accessToken')) {
        this.$store.dispatch('auth/getProfile', null, { root: true })
      }
    }
  },

  render: h => h(App),
}).$mount('#app')
