<template>
  <div>
    <v-menu
      ref="menuRef"
      v-model="menuOpen"
      :dark="isDark"
      :open-on-click="true"
      :close-on-content-click="false"
      transition="slide-x-transition"
      offset-y
      min-width="auto"
    >
      <template v-slot:activator="{ on, attrs, props: menuProps }">
        <v-text-field
          v-model="localDateTime"
          v-bind="{ ...attrs, ...menuProps, ...$attrs }"
          autocomplete="off"
          aria-autocomplete="none"
          :name="inputName"
          :label="inputLabel"
          :rules="rules"
          :error-messages="errorMessages"
          v-on="on"
          @blur="cbs.handleInputBlur"
          @input="cbs.handleInputChange"
          @update:error="cbs.handleInputError"
        ></v-text-field>
      </template>

      <v-card>
        <v-card-text class="px-0 pt-0">
          <v-tabs
            v-model="activeTab"
            fixed-tabs
          >
            <v-tab key="datePicker">
              <slot name="dateIcon">
                <v-icon>{{ icons.mdiCalendar }}</v-icon>
              </slot>
            </v-tab>
            <v-tab
              key="timePicker"
              :disabled="dateSelected"
            >
              <slot name="timeIcon">
                <v-icon>{{ icons.mdiClockOutline }}</v-icon>
              </slot>
            </v-tab>
            <v-tab-item key="datePicker">
              <v-date-picker
                ref="datePickerRef"
                v-model="localDate"
                no-title
                :show-current="showCurrent"
                :rules="[rules]"
                @input="cbs.showTimePicker"
              ></v-date-picker>
            </v-tab-item>
            <v-tab-item key="timePicker">
              <v-time-picker
                ref="timePickerRef"
                v-model="localTime"
                no-title
                format="24hr"
                class="v-time-picker-custom"
                :rules="[rules]"
              ></v-time-picker>
            </v-tab-item>
          </v-tabs>
        </v-card-text>

        <v-card-actions>
          <slot
            name="actions"
            :parent="this"
          >
            <v-row align="center">
              <v-col align="center">
                <v-btn
                  small
                  outlined
                  color="secondary"
                  @click.native="cbs.handleDiscard"
                >
                  {{ t('Discard') }}
                </v-btn>
              </v-col>
              <v-col align="center">
                <v-btn
                  v-if="localDate && localTime"
                  small
                  color="primary"
                  @click="cbs.handleSubmit"
                >
                  {{ t('Submit') }}
                </v-btn>
                <v-btn
                  v-else
                  small
                  color="primary"
                  :disabled="(!localDate && !activeTab)"
                  @click="cbs.handleSelect"
                >
                  <span v-if="activeTab === 0">{{ t('Select Time') }}</span>
                  <span v-else-if="activeTab === 1">{{ t('Select Date') }}</span>
                </v-btn>
              </v-col>
            </v-row>
          </slot>
        </v-card-actions>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
import useAppConfig from '@core/@app-config/useAppConfig'
// eslint-disable-next-line object-curly-newline
import { ref, computed, watch, getCurrentInstance } from '@vue/composition-api'
import { useUtils } from '@/@core/libs/i18n'
import { mdiCalendar, mdiClockOutline } from '@mdi/js'
// eslint-disable-next-line no-unused-vars
import { useCoreUtils } from '@/@core/utils/utils'
import { regexValidator } from '@/@core/utils/validation'

export default {
  name: 'DateTimePicker',

  inheritAttrs: false,

  model: {
    prop: 'dateTime',
    event: 'update',
  },

  props: {
    dateTime: {
      type: String,
      default: () => '',

      // default: () => today().toFormat(DEFAULT_DATETIME_FORMAT),
    },

    inputLabel: {
      type: String,
      default: () => 'Date',
    },

    inputName: {
      type: String,
      default: () => 'date',
    },

    rules: {
      type: Array,
      default: () => [regexValidator()],
    },
    errorMessages: {
      type: Array,
      default: () => [],
    },

    showCurrent: {
      type: Boolean,
      default: true,
    },
  },

  setup(props, { emit }) {
    const vm = getCurrentInstance().proxy
    const { t } = useUtils()
    const { isDark } = useAppConfig()

    const { isValidDate, isValidTime, isValidDateTime } = useCoreUtils()

    const activeTab = ref(0)
    const menuOpen = ref(false)

    const menuRef = ref(null)
    const datePickerRef = ref(null)
    const timePickerRef = ref(null)

    const localDateTime = ref(props.dateTime?.trim())

    const localDate = ref(props.dateTime?.trim()?.split(' ')?.[0] || null)
    const localTime = ref(props.dateTime?.trim()?.split(' ')?.[1] || null)

    const dateSelected = computed(() => !localDate.value)

    const resetTimePickerView = () => {
      if (!timePickerRef?.value || timePickerRef?.value?.selectingHour) return
      timePickerRef.value.selectingHour = true
    }

    const showDatePicker = () => {
      activeTab.value = 0
    }
    const showTimePicker = () => {
      activeTab.value = 1
    }

    const resetPicker = () => {
      menuOpen.value = false
      showDatePicker()
      resetTimePickerView()
    }

    const clearPicker = () => {
      localTime.value = null
      localDate.value = null

      // localDateTime.value = null
    }

    const handleSubmit = () => {
      resetPicker()
      emit('update:dateTime', localDateTime.value)
    }

    const handleDiscard = () => {
      resetPicker()
      clearPicker()
      emit('update:dateTime', null)
    }

    const handleInputBlur = () => {
      if (menuOpen.value) return
      if (localDate.value && localTime.value) {
        localDateTime.value = String(localDate.value).concat(' ').concat(localTime.value)
      } else {
        localDateTime.value = String(localDateTime.value).replace(/(\d{4}-\d{2}-\d{2}\s+\d{2}:\d{2})/g, '')
      }
    }

    const handleInputChange = (dateTime = '') => {
      if (!dateTime) {
        vm.$nextTick(() => clearPicker())
        vm.$nextTick(() => resetPicker())

        return
      }

      try {
        const [date = null, time = null, ...args] = String(dateTime).trim().split(' ', 2)
        if (args.length) localDateTime.value = String(date).concat(' ').concat(time)

        if (!date || !time) return

        localDate.value = isValidDate(date) ? date : null
        localTime.value = isValidTime(time) ? time : null
      } catch (e) {
        vm.$nextTick(() => clearPicker())
      }
    }

    watch(localDateTime, dt => {
      if (dt) {
        localDateTime.value = dt.replace(/[^\d\s-:]/g, '')
      }
    })

    watch(menuOpen, menuVisible => !menuVisible && handleInputBlur())

    watch(activeTab, tab => !tab && resetTimePickerView())

    watch([localDate, localTime], ({ 0: date, 1: time }) => {
      // eslint-disable-next-line no-underscore-dangle
      const _dateTime = String(date).concat(' ').concat(time)
      if (isValidDateTime(_dateTime)) {
        localDateTime.value = _dateTime
      }
    })

    const handleSelect = () => (activeTab.value === 0 ? showTimePicker() : showDatePicker())

    const handleInputError = isError => {
      if (isError) {
        clearPicker()
        resetPicker()
      }
    }

    return {
      t,
      isDark,

      icons: {
        mdiClockOutline,
        mdiCalendar,
      },

      menuOpen,
      activeTab,

      menuRef,
      datePickerRef,
      timePickerRef,

      localDate,
      localTime,
      localDateTime,

      dateSelected,

      cbs: {
        handleSubmit,
        handleDiscard,
        showTimePicker,

        handleSelect,
        handleInputBlur,
        handleInputError,
        handleInputChange,
      },
    }
  },
}
</script>
